import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Rollbar from 'mangools-commons/dist/configs/rollbar';
import Emoji from './Emoji';

const ERROR_TYPES = {
  CHUNK_LOAD: 'ChunkLoadError',
  OTHER: 'OTHER',
};

type AsyncLoaderErrorBoundaryContainerProps = {
  children: ReactNode | ReactNode[];
}

const AsyncLoaderErrorBoundaryContainer = ({ children }: AsyncLoaderErrorBoundaryContainerProps) => {
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState<string>('');

  const online = useSelector((state: any) => state.ui.misc.onlineStatus);

  const getErrorType = (error: Error): string => {
    return error.name === ERROR_TYPES.CHUNK_LOAD ? ERROR_TYPES.CHUNK_LOAD : ERROR_TYPES.OTHER;
  };

  useEffect(() => {
    const handleError = (error: Error) => {
      setError(true);
      setErrorType(getErrorType(error));
      if (online) {
        Rollbar.error(error);
      }
    };

    const errorHandler = (event: ErrorEvent) => {
      handleError(event.error);
    };

    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, [online, Rollbar]);

  const handleReload = () => {
    location.reload();
  };

  const renderBody = () => {
    if (online) {
      if (errorType === ERROR_TYPES.CHUNK_LOAD) {
        return (
          <div className="mg-padding-30">
            <h1 className="font-36 mg-margin-b-30">
              <FontAwesomeIcon icon="info-circle" className="mg-margin-r-10 uk-hidden-small"/>
              Application outdated
            </h1>
            <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
              Looks like there is a <strong>new application version available</strong>.<br/>
              To get the newest version, reload this page, please.
            </p>
            <button className="mg-btn is-blue" onClick={handleReload} type="button">
              <FontAwesomeIcon icon="sync" className="mg-margin-r-10"/>
              Reload page
            </button>
          </div>
        );
      } else {
        return (
          <div className="mg-padding-30">
            <h1 className="font-36 mg-margin-b-30">
              Sorry, something went wrong <Emoji symbol="😢" label="crying-face"/>
            </h1>
            <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
              Looks like there was an <strong>error in the application</strong>.<br/>
              If you see this repeatedly, contact our support please.
            </p>
          </div>
        );
      }
    } else {
      return (
        <div className="mg-padding-30">
          <h1 className="font-36 mg-margin-b-30">
            <FontAwesomeIcon icon="plug" className="mg-margin-r-10 uk-hidden-small"/>
            Connection&nbsp;problem
          </h1>
          <p className="uk-margin-top font-24 color-grey mg-margin-b-30">
            Looks like you have <strong>lost connection</strong> to the server or the
            <br/>
            <strong>request was blocked by a 3rd party application</strong>
            <br/>
            (e.g. browser extension like adBlock).
          </p>
          <button className="mg-btn is-blue" onClick={handleReload} type="button">
            <FontAwesomeIcon icon="sync" className="mg-margin-r-10"/>
            Reload page
          </button>
        </div>
      );
    }
  };

  if (error) {
    return (
      <div className="kwfinder-main">
        <div className="uk-container uk-container-center">
          <div className="uk-flex uk-flex-middle uk-flex-center uk-position-cover">
            {renderBody()}
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default AsyncLoaderErrorBoundaryContainer;
