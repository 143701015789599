import queryString from 'query-string';
import omit from 'ramda/src/omit';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app';

export const currentRouteSelector = () => window.location.pathname;
export const currentQuerySelector = () => queryString.parse(window.location.search);

export const signInRedirectSelector = () => {
    const route = currentRouteSelector();
    const query = currentQuerySelector();

    return queryString.stringifyUrl({
        url: `${APP_CONFIG.APPS_ROOT_HOST}${route}`,
        query: omit(['sso_ticket', 'login_token'], query),
    });
};