import { SourceService } from 'mangools-commons/dist/services/SourceService';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import { parse } from 'sources/parsers/SerpwatcherTrackingDataParser';

const ENDPOINT = '/trackings';

class SerpwatcherTrackingSource extends SourceService {
    static getData({ accessToken }) {
        const request = SerpwatcherTrackingSource.createRequest({ method: 'GET', parse });

        return request({
            requestURL: `${APP_CONFIG.API_HOST}${APP_CONFIG.SERPWATCHER_API_PATH}${ENDPOINT}`,
            headers: {
                'X-Access-Token': accessToken,
            },
        });
    }
}

export default SerpwatcherTrackingSource;
