import queryString from 'query-string';
import Paths from 'mangools-commons/dist/constants/Paths';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app';
import RoutePaths from 'constants/RoutePaths';
import { routerGoBack, routerNavigate, routerReplace } from 'mangools-react-components/src';

class RouterService {
    static navigate(route, params = {}) {
        const translatedParams = {};

        if (route === RoutePaths.DASHBOARD) {
            translatedParams.import_id = params.importId;
            translatedParams.language_id = params.languageId;
            translatedParams.list_id = params.listId;
            translatedParams.location_id = params.locationId;
            translatedParams.query = params.query;
            translatedParams.source_id = params.source;
            translatedParams.sub_source_id = params.subSource;
        }

        if (route === RoutePaths.SW_NEW_TRACKING) {
            translatedParams.kws = params.kws;
            translatedParams.local_list_id = params.local_list_id;
            translatedParams.url = params.url;
            translatedParams.location_id = params.location_id;
        }

        const searchParams = queryString.stringify(translatedParams);
        const data = {
            pathname: route,
            search: searchParams ? `?${searchParams}` : '',
        };

        routerNavigate(data);
    }

    static replace(route, params = {}) {
        const searchParams = queryString.stringify(params);
        const data = {
            pathname: route,
            search: searchParams ? `?${searchParams}` : '',
        };
        routerReplace(data);
    }

    static replaceWithParams(data) {
        routerReplace(data);
    }

    static navigateToRoot() {
        routerNavigate({ pathname: RoutePaths.ROOT, search: '' });
    }

    static navigateBack() {
        routerGoBack();
    }

    static isSame(pathname, routePath) {
        return pathname === routePath || pathname === `/${routePath}`;
    }

    static redirect(url, query = {}) {
        const stringifiedQuery = queryString.stringify(query);
        window.location.replace(
            `${url}${stringifiedQuery.length > 0 ? `?${stringifiedQuery}` : ''}`
        );
    }

    static redirectToAuth(redirectUrl) {
        RouterService.redirect(
            `${APP_CONFIG.MANGOOLS_API_HOST}${Paths.MANGOOLS_AUTH_PATH}`,
            { redirect: redirectUrl }
        );
    }
}

export default RouterService;
