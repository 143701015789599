import React from 'react';

type AlertCloseButtonProps = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const AlertCloseButton = ({ onClick }: AlertCloseButtonProps) => {

  return (
    <button className="toastify-close-button" onClick={onClick}>
      <div className="toastify-close-button-line toastify-close-button-line-rotate-45"/>
      <div className="toastify-close-button-line toastify-close-button-line-rotate-135"/>
    </button>
  );
}