import * as React from 'react';
import Urls from 'mangools-commons/dist/constants/Urls';

import Emoji from './Emoji';
import Image from './Image';

type PricingMessageProps = {
  userPlanType: string;
  colorScheme: string;
  juiceImage: string;
  mangoolsLogoImage: string;
  mangoolsLogoWhiteImage: string;
  queryParams: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const PricingMessageContent = ({
  colorScheme,
  juiceImage,
  mangoolsLogoImage,
  mangoolsLogoWhiteImage,
  queryParams,
  onClick,
}: PricingMessageProps) => {
  const renderUpgradePrompt = () => {
    return (
      <div>
        <p className="mg-margin-b-30">
          You’ve used all of your searches.
          <br />
          Please upgrade, or wait for the counters to reset.
        </p>
        <p>
          <a
            className="mg-btn is-green"
            href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?${queryParams}`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
          >
            <strong>Upgrade my plan</strong>
          </a>
        </p>
        <p className="font-14 color-grey">
          Stay cool, we have a 48h money back guarantee!
        </p>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <h3 className="font-24 uk-text-bold">
        You&apos;re doing too much
        <span className="mg-margin-l-5">
          <Emoji symbol="😊" label="smiling-face-with-smiling-eyes" />
        </span>
      </h3>
    );
  };

  return (
    <>
      <div className="mg-modal-header is-mngls bg-fff">
        <img src={juiceImage} alt="Upgrade my plan" width="110" />
      </div>
      <div className="mg-modal-content bg-fff">
        {renderHeader()}
        {renderUpgradePrompt()}
        <Image
          className="mg-margin-b-5 mg-margin-t-15"
          lightSrc={mangoolsLogoImage}
          darkSrc={mangoolsLogoWhiteImage}
          colorScheme={colorScheme}
          alt="Mangools logo"
          width="140"
        />
        <div className="font-14">Juicy SEO tools you will love</div>
      </div>
    </>
  );
};

export default PricingMessageContent;
