import React from 'react';
import { number } from 'prop-types';
import { DESKTOP } from 'mangools-commons/dist/constants/Platforms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PlatformIcon(props) {
    let icon = null;

    if (props.platformId === DESKTOP.id) {
        icon = DESKTOP.iconName;
    } else {
        icon = 'mobile-alt';
    }

    return (
        <div className="kw-platform-icon uk-flex-inline uk-flex-center">
            <FontAwesomeIcon icon={icon} className="color-grey font-12 mg-margin-r-10" />
        </div>
    );
}

PlatformIcon.propTypes = {
    platformId: number.isRequired,
};

export default PlatformIcon;
