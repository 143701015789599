import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import isNil from 'ramda/src/isNil';

import ActionTypes from 'constants/ActionTypes';
import { ANYWHERE } from 'mangools-commons/dist/constants/Locations';
import { ANY_LANGUAGE } from 'mangools-commons/dist/constants/Languages';

import DataSubSourceTypes from 'constants/DataSubSourceTypes';
import KeywordGraphTypes from 'constants/KeywordGraphTypes';
import DataSourceTypes from 'constants/DataSourceTypes';

const initialState = {
    competitorMode: {
        competitorRowExpanded: true,
        location: ANYWHERE,
        query: '',
    },
    googleMode: {
        language: ANY_LANGUAGE,
        location: ANYWHERE,
        query: '',
    },
    shared: {
        currentKeywordGraph: KeywordGraphTypes.SEARCH_VOLUME,
        dataSource: DataSourceTypes.GOOGLE,
        dataSubSource: DataSubSourceTypes.ADWORDS_RELATED_KEYWORDS,
    },
};

const defaultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DEFAULTS_SHARED_CURRENT_KEYWORD_GRAPH_SET: {
            return update(state, {
                shared: {
                    currentKeywordGraph: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_SHARED_DATA_SUB_SOURCE_SET: {
            return update(state, {
                shared: {
                    dataSubSource: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_SHARED_DATA_SOURCE_SET: {
            return update(state, {
                shared: {
                    dataSource: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_GOOGLE_QUERY_SET: {
            return update(state, {
                googleMode: {
                    query: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_GOOGLE_LANGUAGE_SET: {
            return update(state, {
                googleMode: {
                    language: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_GOOGLE_LOCATION_SET: {
            return update(state, {
                googleMode: {
                    location: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_COMPETITOR_ROW_EXPANDED_SET: {
            return update(state, {
                competitorMode: {
                    competitorRowExpanded: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_COMPETITOR_QUERY_SET: {
            return update(state, {
                competitorMode: {
                    query: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_COMPETITOR_LOCATION_SET: {
            return update(state, {
                competitorMode: {
                    location: { $set: action.payload },
                },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.defaults)) {
                return update(state, {
                    competitorMode: {
                        competitorRowExpanded: {
                            $set: !isNil(savedState.defaults.competitorMode.competitorRowExpanded)
                                ? savedState.defaults.competitorMode.competitorRowExpanded
                                : initialState.competitorMode.competitorRowExpanded,
                        },
                        location: {
                            $set: savedState.defaults.competitorMode.location || initialState.competitorMode.location,
                        },
                    },
                    googleMode: {
                        language: {
                            $set: savedState.defaults.googleMode.language || initialState.googleMode.language,
                        },
                        location: {
                            $set: savedState.defaults.googleMode.location || initialState.googleMode.location,
                        },
                    },
                    shared: {
                        currentKeywordGraph: {
                            $set:
                                savedState.defaults.shared.currentKeywordGraph ||
                                initialState.shared.currentKeywordGraph,
                        },
                        dataSource: {
                            $set: savedState.defaults.shared.dataSource || initialState.shared.dataSource,
                        },
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default defaultsReducer;
