import React, { useRef, useEffect } from 'react';
import { bool, string, func } from 'prop-types';
import ReactList from 'react-list';
import { Input } from 'mangools-react-components/src';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/dist/constants/Shortcuts';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, prop, includes, isEmpty, isNil } from 'ramda';
import Urls from 'mangools-commons/dist/constants/Urls';
import queryString from 'query-string';
import ShortcutService from 'mangools-commons/dist/services/ShortcutService';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import UsercomService from 'mangools-commons/dist/services/UsercomService';

import SerpwatcherTrackingsDataType from 'types/SerpwatcherTrackingsDataType';

import TrackingItem from 'components/messages/TrackInSerpwatcherMessage/TrackingItem';

import { DEFAULT_DEBOUNCE_MS } from 'constants/Other';
import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER, SEARCH } from 'constants/Shortcuts';
import { analyticsEvents, analyticsActions } from 'constants/analytics';
import { userEventNames } from 'constants/usercom';

import { gtmTrack } from 'actions/analyticsActions';

import { useSearch } from 'hooks/useSearch';
import { useFilteredState } from 'hooks/useFilteredState';

function Trackings(props) {
    const dispatch = useDispatch();

    const { swTrackingsById, swTrackingsAllIds } = props.serpwatcherTrackings;

    const listRef = useRef(null);
    const inputRef = useRef(null);
    const [{ search, sanitizedSearch }, setSearch] = useSearch();

    const filteredTrackingsIds = filter(
        trackingId => includes(sanitizedSearch, prop('domain')(swTrackingsById[trackingId])),
        swTrackingsAllIds,
    );

    const [selectedTrackingId, setSelectedTrackingId] = useFilteredState({
        initialValue: null,
        search: sanitizedSearch,
        data: filteredTrackingsIds,
        selector: filteredTrackingIds => filteredTrackingIds[0],
    });

    useEffect(() => {
        if (!isEmpty(filteredTrackingsIds)) {
            setSelectedTrackingId(filteredTrackingsIds[0]);
        }
    }, [swTrackingsAllIds]);

    const handleTrackInSWExistingClick = () => {
        UsercomService.createEvent({ eventName: userEventNames.KWF_TRACK_IN_SW });
        dispatch(
            gtmTrack({
                action: analyticsActions.TRACK_IN_SW_ADD_EXISTING,
                event: analyticsEvents.TRACK_IN_SW,
            }),
        );
        const url = queryString.stringifyUrl({
            url: `${Urls.SERPWATCHER_APP_URL}/tracking`,
            query: {
                id: swTrackingsById[selectedTrackingId].id,
                list_id: props.listId,
            },
        });

        window.open(url, '_blank');
        props.onClose();
    };

    const handleTrackInSWNew = () => {
        UsercomService.createEvent({ eventName: userEventNames.KWF_TRACK_IN_SW });
        dispatch(
            gtmTrack({
                action: analyticsActions.TRACK_IN_SW_ADD_NEW,
                event: analyticsEvents.TRACK_IN_SW,
            }),
        );
        const url = queryString.stringifyUrl({
            url: `${Urls.SERPWATCHER_APP_URL}/new`,
            query: {
                list_id: props.listId,
            },
        });

        window.open(url, '_blank');
        props.onClose();
    };

    const handleArrowDown = e => {
        e.preventDefault();
        const currentIndex = filteredTrackingsIds.indexOf(selectedTrackingId);

        if (!isNil(filteredTrackingsIds[currentIndex + 1])) {
            setSelectedTrackingId(filteredTrackingsIds[currentIndex + 1]);
            listRef.current.scrollAround(currentIndex + 2);
        }
    };

    const handleArrowUp = e => {
        e.preventDefault();
        const currentIndex = filteredTrackingsIds.indexOf(selectedTrackingId);

        if (!isNil(filteredTrackingsIds[currentIndex - 1])) {
            setSelectedTrackingId(filteredTrackingsIds[currentIndex - 1]);
            listRef.current.scrollAround(currentIndex - 2);
        }
    };

    const handleSearchShortcut = e => {
        e.preventDefault();
        inputRef.current.focus();
    };

    const handleClearSearchInput = () => {
        inputRef.current.blur();
        setSearch('');
    };

    const handleSearchChange = e => {
        setSearch(e.target.value);
    };

    const renderItems = (items, ref) => {
        return (
            <ul
                aria-label="Currency list"
                role="menu"
                ref={ref}
                // eslint-disable-next-line max-len
                className="mg-listnav is-auto is-small uk-overflow-container kw-currency-list font-14 uk-height-1-1 mg-card"
            >
                {items}
            </ul>
        );
    };

    const renderShortcuts = () => {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [ARROW_DOWN]: { func: handleArrowDown },
                [ARROW_UP]: { func: handleArrowUp },
                [ENTER]: {
                    func: handleTrackInSWExistingClick,
                    isDisabledCb: false,
                },
                [SEARCH]: { func: handleSearchShortcut },
            },
            areShortcutsDisabled: false,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers} />;
    };

    const renderItem = index => {
        const trackingId = filteredTrackingsIds[index];
        const tracking = swTrackingsById[trackingId];

        return (
            <TrackingItem
                key={trackingId}
                trackingId={trackingId}
                tracking={tracking}
                onClick={setSelectedTrackingId}
                selectedTrackingId={selectedTrackingId}
            />
        );
    };

    function hasNoSearchResults() {
        if (search.length > 0 && swTrackingsAllIds.length > 0 && filteredTrackingsIds.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    function hasNoTrackings() {
        if (props.fetchingSerpwatcherTrackings === false && swTrackingsAllIds.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    function renderNoResults() {
        const noResultsText = hasNoTrackings() ? `You haven't created any trackings yet` : 'Sorry, no results here';
        return (
            <div
                className="mg-border-b font-14 uk-text-left mg-padding-0-15"
                style={{ lineHeight: '48px', pointerEvents: 'none' }}
            >
                {noResultsText}
            </div>
        );
    }

    const hasNoResults = hasNoSearchResults() || hasNoTrackings();

    if (props.fetchingSerpwatcherTrackings) {
        return (
            // eslint-disable-next-line max-len
            <ul className="mg-listnav is-small uk-overflow-container kw-currency-list font-14 uk-height-1-1 mg-card">
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-3-4" />
                    </div>
                </li>
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-2-3" />
                    </div>
                </li>
                <li>
                    <div>
                        <div className="mg-preloader-block uk-width-3-4" />
                    </div>
                </li>
            </ul>
        );
    } else {
        return (
            <>
                {renderShortcuts()}
                <div className="uk-position-relative">
                    <Input
                        autoFocus
                        className="mg-input is-search in-dropdown font-14"
                        debounceMs={DEFAULT_DEBOUNCE_MS}
                        inputRef={inputRef}
                        onChange={handleSearchChange}
                        onClear={handleClearSearchInput}
                        placeholder="Search for a tracking..."
                        spellCheck={false}
                        type="text"
                        value={search}
                        disabled={hasNoTrackings()}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                    />
                    <button
                        tabIndex="-1"
                        className={classnames('mg-icon-btn', 'uk-position-top-right', 'font-10', 'uk-height-1-1', {
                            'uk-hidden': search.length === 0,
                        })}
                        onClick={handleClearSearchInput}
                        type="button"
                        style={{ zIndex: 2, width: '36px' }}
                    >
                        <FontAwesomeIcon icon="times" aria-label="Clear" />
                    </button>
                </div>
                <div className="mg-card is-focusable uk-flex-item-auto uk-overflow-container" tabIndex="-1">
                    {hasNoResults ? (
                        renderNoResults()
                    ) : (
                        <ReactList
                            itemRenderer={renderItem}
                            itemsRenderer={renderItems}
                            length={filteredTrackingsIds.length}
                            type="uniform"
                            updateWhenDataChange={swTrackingsById}
                            ref={listRef}
                        />
                    )}
                </div>
                <button
                    // eslint-disable-next-line max-len
                    className="mg-btn is-green uk-width-1-1 mg-padding-0-15 mg-margin-t-15 mg-margin-b-10 uk-flex-item-none"
                    onClick={handleTrackInSWExistingClick}
                    type="button"
                    disabled={hasNoResults}
                >
                    Add to tracking
                </button>
                <div className="uk-text-center mg-margin-b-10">or</div>
                <button
                    className="mg-btn is-white uk-width-1-1 mg-padding-0-15 uk-flex-item-none"
                    onClick={handleTrackInSWNew}
                    type="button"
                >
                    Create new tracking
                </button>
            </>
        );
    }
}

Trackings.propTypes = {
    fetchingSerpwatcherTrackings: bool.isRequired,
    serpwatcherTrackings: SerpwatcherTrackingsDataType.isRequired,
    listId: string.isRequired,
    onClose: func.isRequired,
};

export default Trackings;
