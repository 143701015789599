// const PARSED_DATA = [{
//     id: '567176272a0225a07cbbc66e',
//     createdAt: 1450276391,
//     keywordId: '782da73af646e66cde0a43e1d5be7c41',
//     keyword: 'flights to london',
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         label: 'Slovakia'
//     },
//     rank: 32,
//     rankUpdatedAt: 15300023,
// }, {
//     id: '567175c8acd6b173270eecca',
//     createdAt: 1450276296,
//     keywordId: '1e34c04c22784007bdd6088f940159ec',
//     keyword: 'hotel',
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         label: 'Slovakia'
//     },
//     rank: 25,
//     rankUpdatedAt: 15300023,
// }];

import reverse from 'ramda/src/reverse';
import isNil from 'ramda/src/isNil';

import { parseIntWithNVFallback, parseLocationLabel, parseStringWithNAFallback } from 'sources/parsers/commons';

import { ANY_LANGUAGE } from 'mangools-commons/dist/constants/Languages';
import { ANYWHERE } from 'mangools-commons/dist/constants/Locations';

const parseLanguage = rawLanguage => ({
    code: rawLanguage.code,
    id: parseInt(rawLanguage._id, 10), // eslint-disable-line no-underscore-dangle
    label: rawLanguage.label,
});

const parseLocation = rawLocation => ({
    id: parseInt(rawLocation._id, 10), // eslint-disable-line no-underscore-dangle
    label: parseLocationLabel(rawLocation.label),
});

export const parse = rawData =>
    reverse(
        rawData.map(item => {
            const type = parseInt(item.t, 10);

            return {
                id: item._id, // eslint-disable-line no-underscore-dangle
                language: !isNil(item.language) ? parseLanguage(item.language) : ANY_LANGUAGE,
                location: !isNil(item.location) ? parseLocation(item.location) : ANYWHERE,
                query: parseStringWithNAFallback(type === 0 ? item.kw : item.url),
                rank: parseIntWithNVFallback(item.rank),
                rankUpdatedAt: parseIntWithNVFallback(item.rank_ts),
                type,
            };
        }),
    );
