import React from "react";

type RefreshIconProps = {
  style?: React.CSSProperties;
}

export const RefreshIcon = ({ style }: RefreshIconProps) => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_67_1600)">
        <path
          d="M5.6959 1.13923C6.99021 1.14155 8.16537 1.65159 9.03299 2.48073L9.73055 1.78317C10.0259 1.48788 10.5308 1.69702 10.5308 2.11464V4.73298C10.5308 4.99187 10.3209 5.20173 10.062 5.20173H7.44367C7.02605 5.20173 6.81691 4.69683 7.11221 4.40151L7.92764 3.58608C7.32482 3.02165 6.5448 2.70899 5.71611 2.70185C3.91146 2.68626 2.39027 4.14671 2.40588 6.01126C2.42068 7.78003 3.85467 9.26423 5.68701 9.26423C6.49027 9.26423 7.24945 8.97755 7.84773 8.45259C7.94037 8.37132 8.08027 8.37626 8.16742 8.46339L8.94207 9.23804C9.03723 9.33319 9.03252 9.48833 8.93266 9.57853C8.07377 10.3543 6.93557 10.8267 5.68701 10.8267C3.01189 10.8267 0.843281 8.65812 0.843262 5.98302C0.843242 3.31097 3.02385 1.13444 5.6959 1.13923Z"
          fill="black"/>
      </g>
    </svg>
  );
}