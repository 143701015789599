import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import HumanService from 'mangools-commons/dist/services/HumanService';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app';

const ENDPOINT = '/search-intent';

class SearchIntentSource {
    static getData({ accessToken, params }) {
        const { relatedKeywordId, keywords } = params;

        const requestBody = {};
        if (relatedKeywordId) {
            requestBody.relatedKeywordId = relatedKeywordId;
        } else if (Array.isArray(keywords) && keywords.length > 0) {
            requestBody.keywordIds = keywords.map(({ id }) => id)
        } else {
            return Promise.resolve({
                error: false,
                payload: {},
            });
        }

        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: data,
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default SearchIntentSource;
