import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import concat from 'ramda/src/concat';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
        type: null,
    },
    fetching: false,
    fetchingMore: false,
    nextPage: 0,
    pageBreakpoints: [],
    updatedAt: Defaults.NO_VALUE,
};

const serpResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_IMPORT_FETCHING:
        case ActionTypes.DATA_LISTS_KEYWORDS_FETCHING:
        case ActionTypes.DATA_RESULTS_GOOGLE_FETCHING:
        case ActionTypes.DATA_SERP_RESULTS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_RECEIVED: {
            return update(state, {
                data: { $set: action.payload.serpData },
                error: { $set: initialState.error },
                fetching: { $set: false },
                nextPage: { $set: 1 },
                pageBreakpoints: { $set: [action.payload.serpData.length] },
                updatedAt: { $set: action.payload.updatedAt },
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
                pageBreakpoints: { $set: initialState.pageBreakpoints },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
                pageBreakpoints: { $set: initialState.pageBreakpoints },
            });
        }
        case ActionTypes.DATA_RESULTS_GOOGLE_ERROR:
        case ActionTypes.DATA_IMPORT_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
                pageBreakpoints: { $set: initialState.pageBreakpoints },
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_MORE_FETCHING: {
            return update(state, {
                fetchingMore: { $set: true },
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_MORE_RECEIVED: {
            return update(state, {
                data: { $apply: data => concat(data, action.payload.serpData) },
                error: { $set: initialState.error },
                fetchingMore: { $set: false },
                nextPage: { $apply: nextPage => nextPage + 1 },
                pageBreakpoints: {
                    $apply: breakpoints => concat(breakpoints, [state.data.length + action.payload.serpData.length]),
                },
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_MORE_ERROR: {
            return update(state, {
                fetchingMore: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_ERROR: {
            // Setting same error as competitor error to serp results,
            // but only if it is a our of limits error
            if (action.payload.status === ErrorCodes.UNATHORIZED) {
                return update(state, {
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                        type: { $set: action.payload.type },
                    },
                });
            } else {
                return update(state, {
                    $set: initialState,
                });
            }
        }
        case ActionTypes.DATA_SERP_RESULTS_NO_DATA_RECEIVED: {
            return update(state, {
                $set: initialState,
            });
        }
        case ActionTypes.DATA_SERP_RESULTS_MORE_NO_DATA_RECEIVED: {
            return update(state, {
                fetchingMore: { $set: false },
            });
        }
        case ActionTypes.DATA_RESULTS_GOOGLE_EMPTY: {
            return update(state, {
                $set: initialState,
            });
        }
        case ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_EMPTY: {
            return update(state, {
                $set: initialState,
            });
        }
        default: {
            return state;
        }
    }
};

export default serpResultReducer;
