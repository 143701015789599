import queryString from 'query-string';
import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import { parse } from 'sources/parsers/AnnouncementDataParser';

const ENDPOINT = '/api/announcement';

/**
 * Data source class handling communication with API for user data.
 */
class AnnouncementsSource {
    static getData() {
        const query = queryString.stringify({ r: Date.now() });
        const requestURL = `${APP_CONFIG.MANGOOLS_API_HOST}${ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default AnnouncementsSource;
