import React, { useRef, useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import ReactList from 'react-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isNil } from 'ramda';
import queryString from 'query-string';
import { Input, ListNavigation, withVisibilityLogic } from 'mangools-react-components/src';
import classnames from 'classnames';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/dist/constants/Shortcuts';

import ListPanelItem from 'components/panels/listPanel/ListPanelItem';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER, SEARCH } from 'constants/Shortcuts';
import { DEFAULT_DEBOUNCE_MS } from 'constants/Other';

import RoutePaths from 'constants/RoutePaths';

import sadImg from 'images/sad.svg';

import ListType from 'types/ListType';

const ListPanel = React.memo(props => {
    const filterInputRef = useRef(null);
    const listRef = useRef(null);
    const [preselectedListId, setPreselectedListId] = useState('');

    useEffect(() => {
        props.onOpen();
    }, []);

    useEffect(() => {
        if (props.data.length > 0) {
            setPreselectedListId(props.data[0].id);
        } else {
            setPreselectedListId('');
        }
    }, [props.data]);

    const getListShowLink = listId => {
        const query = queryString.stringify({ list_id: listId });
        return `${RoutePaths.DASHBOARD}?${query}`;
    };

    const handleFilterChange = e => props.onChangeListFilter(e.target.value);

    const handleArrowDown = ([list, index]) => {
        setPreselectedListId(list.id);
        listRef.current.scrollAround(index + 2);
    };

    const handleArrowUp = ([list, index]) => {
        setPreselectedListId(list.id);
        listRef.current.scrollAround(index - 2);
    };

    const handleEnter = () => {
        const { requestNavigation, onClose } = props;

        if (!isEmpty(preselectedListId)) {
            requestNavigation(RoutePaths.DASHBOARD, { listId: preselectedListId });
            onClose();
        }
    };

    const handleListDelete = list => {
        if (list.id === preselectedListId) {
            setPreselectedListId(null);
        }

        props.onDeleteList(list);
    };

    const handleSearch = useCallback(e => {
        e.preventDefault();

        if (!isNil(filterInputRef.current)) {
            filterInputRef.current.focus();
        }
    });

    const handleFilterClear = () => {
        filterInputRef.current.blur();
        props.onChangeListFilter('');
    };

    const renderEmptyListMessage = () => {
        if (!isEmpty(props.listFilter)) {
            return (
                <div className="bg-fff uk-text-center mg-padding-30">
                    <div className="mg-margin-b-15">
                        <img src={sadImg} alt="Oh no" width="160" />
                    </div>
                    <p>Sorry, no list found for given filter.</p>
                </div>
            );
        } else {
            return (
                <div className="bg-fff uk-text-center mg-padding-30">
                    <div className="mg-margin-b-15">
                        <img src={sadImg} alt="Oh no" width="160" />
                    </div>
                    <strong className="font-24">What about creating some lists?</strong>
                    <p>
                        You can create lists by selecting keywords and clicking the &quot;Add to list&quot; button below
                        the table.
                    </p>
                    <p className="color-grey">
                        <em>
                            <strong>Tip:</strong> Lists help you keep your keyword research organized.
                        </em>
                    </p>
                </div>
            );
        }
    };

    const renderItem = (index, _key) => {
        const entry = props.data[index];

        return (
            <ListPanelItem
                createdAt={entry.createdAt}
                id={entry.id}
                key={entry.id}
                keywordCount={entry.keywordIds.length}
                name={entry.name}
                onClose={props.onClose}
                onDeleteList={handleListDelete}
                onExportList={props.onExportList}
                showListLink={getListShowLink(entry.id)}
                updatedAt={entry.updatedAt}
                isPreselected={entry.id === preselectedListId}
                onRenameList={props.onRenameList}
                onShowTrackInSerpwatcherMessage={props.onShowTrackInSerpwatcherMessage}
            />
        );
    };

    const renderItems = (items, ref) => <div ref={ref}>{items}</div>;

    const renderPreloaderRow = () => (
        <div className="mg-card mg-padding-30 mg-margin-b-15 uk-flex uk-flex-space-between uk-flex-middle ln-11">
            <div className="uk-width-8-10">
                <div className="mg-preloader-block uk-width-2-3 font-18 mg-margin-b-15" />
                <div className="uk-flex font-12">
                    <div className="uk-width-1-2">
                        <div className="mg-preloader-block uk-width-2-3" />
                    </div>
                    <div className="uk-width-1-2">
                        <div className="mg-preloader-block uk-width-2-3" />
                    </div>
                </div>
            </div>
        </div>
    );

    const renderPreloader = () => (
        <>
            {renderPreloaderRow()}
            {renderPreloaderRow()}
            {renderPreloaderRow()}
        </>
    );

    const renderBody = () => {
        if (props.fetching || props.exporting) {
            return renderPreloader();
        } else if (!isEmpty(props.data)) {
            return (
                <>
                    {props.trackInSerpwatcherVisible ? null : (
                        <ListNavigation
                            data={props.data}
                            selectedItemId={preselectedListId}
                            onArrowDownShortcut={handleArrowDown}
                            onArrowUpShortcut={handleArrowUp}
                            onEnterShortcut={handleEnter}
                            onSearchShortcut={handleSearch}
                            shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER, SEARCH]}
                            shortcuts={shortcuts}
                        />
                    )}

                    <ReactList
                        itemRenderer={renderItem}
                        itemsRenderer={renderItems}
                        length={props.data.length}
                        type="uniform"
                        updateWhenDataChange={props.data}
                        ref={listRef}
                    />
                </>
            );
        } else {
            return renderEmptyListMessage();
        }
    };

    return (
        <div className="mg-panel">
            <div className="mg-panel-title mg-padding-30">
                <h1 className="uk-margin-remove font-24">
                    <FontAwesomeIcon icon="star" aria-hidden="true" className="color-orange mg-margin-r-10" />
                    <strong>Keyword lists</strong>
                </h1>
                <button className="mg-close" onClick={props.onClose} type="button">
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="mg-panel-content uk-flex uk-flex-column" style={{ overflow: 'hidden' }}>
                <div>
                    {/* eslint-disable-next-line max-len */}
                    <div className="bg-lightgrey mg-padding-0-30 mg-padding-5-0 uk-flex uk-flex-middle uk-position-relative">
                        <Input
                            autoFocus
                            className="mg-input is-void is-search in-dropdown"
                            debounceMs={DEFAULT_DEBOUNCE_MS}
                            inputRef={filterInputRef}
                            onChange={handleFilterChange}
                            onClear={handleFilterClear}
                            placeholder="Search for a list..."
                            spellCheck={false}
                            value={props.listFilter}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                        />
                        <button
                            className={
                                /* eslint-disable-next-line max-len */
                                classnames(
                                    'mg-icon-btn',
                                    'uk-position-top-right',
                                    'font-14',
                                    'uk-height-1-1',
                                    'mg-margin-r-15',
                                    {
                                        'uk-hidden': props.listFilter.length === 0,
                                    },
                                )
                            }
                            onClick={handleFilterClear}
                            type="button"
                            style={{ zIndex: 2, width: '36px' }}
                        >
                            <FontAwesomeIcon icon="times" aria-label="Clear" />
                        </button>
                    </div>
                </div>
                <div className="uk-overflow-container uk-flex-item-auto mg-padding-30" tabIndex="-1">
                    {renderBody()}
                </div>
            </div>
        </div>
    );
});

ListPanel.propTypes = {
    data: arrayOf(ListType).isRequired,
    exporting: bool.isRequired,
    fetching: bool.isRequired,
    listFilter: string.isRequired,
    onChangeListFilter: func.isRequired,
    onClose: func.isRequired,
    onDeleteList: func.isRequired,
    onExportList: func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onOpen: func.isRequired,
    requestNavigation: func.isRequired,
    onRenameList: func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onShowTrackInSerpwatcherMessage: func.isRequired,
    trackInSerpwatcherVisible: bool.isRequired,
};

export default withVisibilityLogic(ListPanel);
