import Environments from 'mangools-commons/dist/constants/Environments';
import { getMangoolsUrls } from 'mangools-commons/dist/constants/getMangoolsUrls';
export const API_VERSION = 'v3';
export const APP_CONFIG = {
  API_HOST: process.env.API_HOST,
  APP_ENV: process.env.NODE_ENV,
  APP_HOST: `${process.env.APPS_ROOT_HOST}/${process.env.APP_NAME}`,
  APP_NAME: process.env.APP_NAME,
  API_PATH: `/${API_VERSION}/${process.env.APP_NAME}`,
  APP_VERSION: process.env.GIT_VERSION,
  APPS_ROOT_HOST: process.env.APPS_ROOT_HOST,
  LOCAL_STORAGE_VERSION: 1,
  MANGOOLS_API_HOST: process.env.MANGOOLS_APP_HOST,
  MANGOOLS_URLS: getMangoolsUrls(),
  ROLLBAR_API_KEY: process.env.ROLLBAR_API_KEY,
  KWFINDER_API_PATH: `/${API_VERSION}/kwfinder`,
  SERPCHECKER_API_PATH: `/${API_VERSION}/serpchecker`,
  SERPWATCHER_API_PATH: `/${API_VERSION}/serpwatcher`,
  development() {
    return process.env.NODE_ENV === Environments.DEVELOPMENT;
  },
  production() {
    return process.env.NODE_ENV === Environments.PRODUCTION;
  },
  staging() {
    return process.env.NODE_ENV === Environments.STAGING;
  }
};