type NavigationProps = {
  pathname: string;
  search: string;
}

export const routerNavigate = (data: NavigationProps) => {
  const event = new CustomEvent('router:navigate', { detail: { data } });
  window.dispatchEvent(event);
}

export const routerReplace = (data: NavigationProps) => {
  const event = new CustomEvent('router:replace', { detail: { data } });
  window.dispatchEvent(event);
}

export const routerGoBack = () => {
  const event = new CustomEvent('router:goBack');
  window.dispatchEvent(event);
}