export const addJavascript = (url) => {
    return new Promise((resolve) => {
        const tag = document.createElement('script');
        tag.src = url;
        tag.async = true;
        tag.onload = resolve;
        document.body.appendChild(tag);
    });
}

export const addCss = (url) => {
    return new Promise((resolve) => {
        const tag = document.createElement('link');
        tag.rel = 'stylesheet';
        tag.href = url;
        tag.onload = resolve;
        document.head.appendChild(tag);
    });
}