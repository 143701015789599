import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

type RouterEventListenerProps = {
  actionOnLocationChange: string;
};

const RouterEventListener = ({ actionOnLocationChange }: RouterEventListenerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDispatchLocationChange = (pathname: string) => {
    dispatch({ type: actionOnLocationChange, payload: { path: pathname } });
  };

  useEffect(() => {
    const handleNavigate = (event: CustomEvent) => {
      const { data } = event.detail;
      navigate(data);
      handleDispatchLocationChange(data.pathname);
    };

    const handleReplace = (event: CustomEvent) => {
      const { data } = event.detail;
      navigate(data, { replace: true });
      handleDispatchLocationChange(data.pathname);
    };

    const handleGoBack = () => {
      navigate(-1);
      handleDispatchLocationChange('goBack');
    };

    window.addEventListener('router:navigate', handleNavigate);
    window.addEventListener('router:replace', handleReplace);
    window.addEventListener('router:goBack', handleGoBack);

    return () => {
      window.removeEventListener('router:navigate', handleNavigate);
      window.removeEventListener('router:replace', handleReplace);
      window.removeEventListener('router:goBack', handleGoBack);
    };
  }, [navigate, dispatch]);

  return null;
};

export default RouterEventListener;
