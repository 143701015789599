import { Id, toast, ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { AlertCloseButton } from './AlertCloseButton';
import { OnChangeCallback } from 'react-toastify/dist/types';

import 'react-toastify/dist/ReactToastify.min.css';
import 'mangools-commons-assets/src/overrides/toastify.scss';

const Alert = () => {
  const [, setOpenToastIds] = useState<Id[]>([]);
  const maxToastLimit = 3;

  useEffect(() => {
    const updateToastCount: OnChangeCallback = ({ id }) => {
      setOpenToastIds((prevToastIds) => {
        if (toast.isActive(id)) {
          if (!prevToastIds.includes(id)) {
            if (prevToastIds.length >= maxToastLimit) {
              toast.dismiss(prevToastIds[0]);
              return [...prevToastIds.slice(1), id];
            }
            return [...prevToastIds, id];
          }
        } else {
          return prevToastIds.filter((toastId) => toastId !== id);
        }
        return prevToastIds;
      });
    };

    const unsubscribe = toast.onChange(updateToastCount);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ToastContainer
      autoClose={3500}
      position={'top-right'}
      hideProgressBar
      closeButton={({ closeToast }) => <AlertCloseButton onClick={closeToast} />}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
  );
};

export default Alert;
