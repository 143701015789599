import React from 'react';
import { call, delay, put, race, select, spawn, takeLatest } from 'redux-saga/effects';
import DownloaderService from 'mangools-commons/dist/services/DownloaderService';
import FileService from 'mangools-commons/dist/services/FileService';
import Defaults from 'mangools-commons/dist/constants/Defaults';
import { isNil } from 'ramda';

import { handleError, handleUncaught, logError } from 'sagas/errorSagas';

import { setExporting, showFailureMessage } from 'actions/uiActions';

import {
    currentListDetailNameSelector,
    filteredAndSortedResultsDataSelector,
    listSelector,
} from 'selectors/dataSelectors';

import { currencySelector } from 'selectors/uiSelectors';

import { showInfoNotification } from 'sagas/uiSagas';

import Strings from 'constants/Strings';
import ActionTypes from 'constants/ActionTypes';
import KeywordSourceTypes from 'constants/KeywordSourceTypes';

import ExportService from 'services/ExportService';
import ResultDataService from 'services/ResultDataService';

import { accessTokenSelector } from 'selectors/userSelectors';

import { KeywordSource } from 'sources/KeywordSource';

const EXPORT_PREFIX = 'kwfinder_';
const EXPORT_SUFFIX = '_export';

const exportListData = handleUncaught(
    function* exportListData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(setExporting(true));
            const list = yield select(listSelector, action.payload.listId);

            const { result, _timeout } = yield race({
                result: call(KeywordSource.getData, { accessToken, keywordIds: list.keywordIds }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!isNil(error)) {
                    const keywords = ResultDataService.calculateSearchVolumeTimeframes(payload);
                    const currency = yield select(currencySelector);
                    const filename = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(
                        list.name,
                    )}${EXPORT_SUFFIX}`;

                    const csv = yield call(ExportService.exportKeywords, keywords, {
                        kwSourceType: KeywordSourceTypes,
                        currency,
                    });

                    const success = yield call(DownloaderService.downloadCSV, filename, csv);

                    if (success) {
                        yield call(showInfoNotification, <div>Your list <strong>{list.name}</strong> was successfully exported.</div>);
                    } else {
                        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
                        yield call(logError, 'ExportListData|DownloaderService');
                    }

                    yield put(setExporting(false));
                } else {
                    const handler = handleError({
                        action,
                        payload,
                        actionName: 'ExportListDataSaga',
                        retrying,
                        saga: exportListData,
                        failureMessage: Strings.messages.failure.export_error,
                    });

                    handler();
                }
            }
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
    },
);

const exportCurrentListData = handleUncaught(
    function* exportCurrentListData() {
        yield put(setExporting(true));
        const sortedKeywords = yield select(filteredAndSortedResultsDataSelector);
        const listName = yield select(currentListDetailNameSelector);
        const currency = yield select(currencySelector);

        const filename = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(listName)}${EXPORT_SUFFIX}`;

        const csv = yield call(ExportService.exportKeywords, sortedKeywords, {
            kwSourceType: KeywordSourceTypes.LIST,
            currency,
        });

        const success = yield call(DownloaderService.downloadCSV, filename, csv);

        if (success) {
            yield call(showInfoNotification, <div>Your list <strong>{listName}</strong> was successfully exported.</div>);
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield call(logError, 'ExportCurrentListDataSaga|DownloaderService');
        }

        yield put(setExporting(false));
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
        yield put(setExporting(false));
    },
);

function* watchExportCurrentListDataRequests() {
    yield takeLatest(ActionTypes.DATA_CURRENT_LIST_EXPORT_REQUESTED, exportCurrentListData);
}

function* watchExportListDataRequests() {
    yield takeLatest(ActionTypes.DATA_LIST_EXPORT_REQUESTED, exportListData);
}

export function* watchExportRequests() {
    yield spawn(watchExportCurrentListDataRequests);
    yield spawn(watchExportListDataRequests);
}
