import { useEffect, useRef, useState } from 'react';

/*
    NOTE:
    Used for search over List collections.
    While searching it preselects the best match.
    If search is cleared, it returns to previously selected match from the original collection.
*/

const useFilteredState = ({ initialValue, search = '', data, selector }) => {
    const [value, setValue] = useState(initialValue);
    const prevValue = useRef(initialValue);

    useEffect(() => {
        if (search.length === 0) {
            prevValue.current = value;
        }
    }, [value]);

    useEffect(() => {
        if (data.length > 0 && search.length > 0) {
            setValue(selector(data));
        } else if (search.length === 0) {
            setValue(prevValue.current);
        }
    }, [search]);

    return [value, setValue];
};

export { useFilteredState };
