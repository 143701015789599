import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useUnleashContext } from '@unleash/proxy-client-react';

import { fetchedSelector, idSelector, unleashSessionSelector } from 'selectors/userSelectors';

import { useInitialLoadScripts } from 'hooks/useInitialLoadScripts';
import FullAppPreloader from 'components/layout/FullAppPreloader';
import { Router } from 'components/Routes';

const AppContainer = () => {
    useInitialLoadScripts();
    const updateContext = useUnleashContext();
    const userId = useSelector(idSelector);
    const userFetched = useSelector(fetchedSelector);
    const sessionId = useSelector(unleashSessionSelector);

    useEffect(() => {
        if (userFetched) {
            updateContext({ sessionId, userId }).catch(console.error);
        }
    }, [sessionId]);

    if (userFetched) {
        return <Router/>;
    } else {
        return <FullAppPreloader/>;
    }
};

export default AppContainer;