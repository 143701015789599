import * as React from 'react';
import ReactImageFallback from 'react-image-fallback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FaviconService from 'mangools-commons/dist/services/FaviconService';
import isNil from 'ramda/src/isNil';

const DefaultFavicon = (className = '', size: string | number) => (
  <FontAwesomeIcon
    icon="globe-americas"
    className={`color-grey ${className} font-${size}`}
  />
);

type FaviconProps = {
  size: number;
  className: string;
  defaultFaviconClassname?: string;
  domain: string;
};

const Favicon = ({
  size = 16,
  className,
  domain,
  defaultFaviconClassname,
}: FaviconProps) => {
  const renderedDefaultFavicon = DefaultFavicon(
    isNil(defaultFaviconClassname) ? className : defaultFaviconClassname,
    size,
  );

  return (
    <ReactImageFallback
      alt="icon"
      className={className}
      fallbackImage={renderedDefaultFavicon}
      initialImage={renderedDefaultFavicon}
      src={FaviconService.getFaviconUrl({ domain })}
      style={{ height: `${size}px`, width: `${size}px` }}
      width={size}
    />
  );
};

export default Favicon;
