import { API_VERSION, APP_CONFIG } from 'mangools-commons/dist/configs/app';
import queryString from 'query-string';
import { parse } from 'sources/parsers/UserDataParser';
import { parse as parseAccountData } from 'sources/parsers/AccountDataParser';
import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import isNil from 'ramda/src/isNil';

const USER_ENDPOINT = `/${API_VERSION}/system/me`;
const LIMITS_ENDPOINT = '/limits';

/**
 * Data source class handling communication with API for user data.
 */
class UserSource {
    static getData({ ssoTicket, loginToken, disableCache = null }) {
        const query = queryString.stringify({
            r: Date.now(),
            sso_ticket: ssoTicket,
            login_token: loginToken,
            ...(!isNil(disableCache) && { disableCache }),
        });
        const requestURL = `${APP_CONFIG.API_HOST}${USER_ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getLimitData(accessToken) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${LIMITS_ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseAccountData(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default UserSource;
