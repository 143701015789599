import React from 'react';

export const SerpSimulatorIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7188 6.63594C11.7188 9.95234 9.44766 12.3125 6.09375 12.3125C2.87813 12.3125 0.28125 9.71562 0.28125 6.5C0.28125 3.28438 2.87813 0.6875 6.09375 0.6875C7.65938 0.6875 8.97656 1.26172 9.99141 2.20859L8.40937 3.72969C6.33984 1.73281 2.49141 3.23281 2.49141 6.5C2.49141 8.52734 4.11094 10.1703 6.09375 10.1703C8.39531 10.1703 9.25781 8.52031 9.39375 7.66484H6.09375V5.66562H11.6273C11.6813 5.96328 11.7188 6.24922 11.7188 6.63594Z"
      fill="#7C7D80"
    />
  </svg>
);

export const RedditThreadsFinderIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3195 4.76943C9.96796 4.76943 9.65859 4.91475 9.43124 5.14209C8.59453 4.56318 7.46718 4.19053 6.21796 4.15068L6.86718 1.22568L8.93437 1.68975C8.93437 2.196 9.34687 2.6085 9.85312 2.6085C10.3687 2.6085 10.7836 2.18428 10.7836 1.67803C10.7836 1.17178 10.3711 0.747559 9.85312 0.747559C9.49218 0.747559 9.18046 0.965527 9.02578 1.26318L6.74296 0.756934C6.62812 0.726465 6.51562 0.808496 6.48515 0.92334L5.77265 4.14834C4.53281 4.1999 3.41718 4.57256 2.57812 5.15146C2.35078 4.91475 2.02968 4.76943 1.67812 4.76943C0.374995 4.76943 -0.0515677 6.51787 1.1414 7.11553C1.09921 7.30068 1.08046 7.49756 1.08046 7.69443C1.08046 9.6585 3.29296 11.2499 6.00937 11.2499C8.73749 11.2499 10.95 9.6585 10.95 7.69443C10.95 7.49756 10.9289 7.29131 10.8773 7.10615C12.0469 6.50615 11.6156 4.76943 10.3195 4.76943ZM3.03281 7.23975C3.03281 6.72412 3.44531 6.30928 3.96328 6.30928C4.46953 6.30928 4.88203 6.72178 4.88203 7.23975C4.88203 7.746 4.46953 8.1585 3.96328 8.1585C3.44765 8.16084 3.03281 7.746 3.03281 7.23975ZM8.05546 9.43115C7.20234 10.2843 4.79531 10.2843 3.94218 9.43115C3.84843 9.34912 3.84843 9.20381 3.94218 9.11006C4.02421 9.02803 4.16953 9.02803 4.25156 9.11006C4.90312 9.77803 7.06406 9.78975 7.74374 9.11006C7.82578 9.02803 7.97109 9.02803 8.05312 9.11006C8.14921 9.20381 8.14921 9.34912 8.05546 9.43115ZM8.03671 8.16084C7.53046 8.16084 7.11796 7.74834 7.11796 7.24209C7.11796 6.72647 7.53046 6.31162 8.03671 6.31162C8.55234 6.31162 8.96718 6.72412 8.96718 7.24209C8.96484 7.746 8.55234 8.16084 8.03671 8.16084Z"
      fill="#7C7D80"
    />
  </svg>
);

export const AISearchGraderIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_144)">
      <path
        d="M4.87501 12.5C4.7565 12.5006 4.64063 12.465 4.54288 12.398C4.44512 12.331 4.37015 12.2358 4.32798 12.125L3.39423 9.69688C3.37532 9.64796 3.34639 9.60352 3.30929 9.56643C3.2722 9.52934 3.22777 9.50041 3.17884 9.48149L0.75001 8.54704C0.639371 8.50458 0.544209 8.42956 0.477084 8.3319C0.409958 8.23424 0.374023 8.11852 0.374023 8.00001C0.374023 7.8815 0.409958 7.76578 0.477084 7.66812C0.544209 7.57046 0.639371 7.49544 0.75001 7.45298L3.17813 6.51923C3.22706 6.50032 3.2715 6.47139 3.30859 6.43429C3.34568 6.3972 3.37461 6.35277 3.39352 6.30384L4.32798 3.87501C4.37044 3.76437 4.44545 3.66921 4.54312 3.60208C4.64078 3.53496 4.7565 3.49902 4.87501 3.49902C4.99352 3.49902 5.10924 3.53496 5.2069 3.60208C5.30456 3.66921 5.37958 3.76437 5.42204 3.87501L6.35579 6.30313C6.3747 6.35206 6.40363 6.3965 6.44072 6.43359C6.47782 6.47068 6.52225 6.49961 6.57118 6.51853L8.98524 7.44735C9.10039 7.49003 9.19958 7.56716 9.26932 7.66823C9.33907 7.7693 9.37597 7.8894 9.37501 8.0122C9.37322 8.12864 9.33652 8.24187 9.26967 8.33722C9.20281 8.43258 9.10887 8.50567 9.00001 8.54704L6.57188 9.48079C6.52296 9.4997 6.47852 9.52863 6.44143 9.56572C6.40434 9.60282 6.37541 9.64725 6.35649 9.69618L5.42204 12.125C5.37987 12.2358 5.30489 12.331 5.20714 12.398C5.10939 12.465 4.99352 12.5006 4.87501 12.5Z"
        fill="#7C7D80"
      />
      <path
        d="M2.06246 4.62506C1.99298 4.62505 1.92513 4.604 1.86785 4.56467C1.81057 4.52534 1.76656 4.46959 1.7416 4.40475L1.34644 3.37724C1.33788 3.35477 1.32466 3.33437 1.30765 3.31736C1.29065 3.30036 1.27024 3.28714 1.24777 3.27857L0.220268 2.88342C0.155435 2.85845 0.0996864 2.81443 0.0603664 2.75715C0.0210464 2.69988 0 2.63203 0 2.56256C0 2.49308 0.0210464 2.42524 0.0603664 2.36796C0.0996864 2.31069 0.155435 2.26666 0.220268 2.2417L1.24777 1.84654C1.27022 1.83794 1.29061 1.82471 1.30761 1.80771C1.3246 1.79071 1.33784 1.77032 1.34644 1.74787L1.73808 0.72951C1.76017 0.669558 1.79817 0.616758 1.84802 0.576784C1.89786 0.536811 1.95765 0.511177 2.02097 0.502635C2.09699 0.493394 2.17392 0.509795 2.23956 0.549238C2.3052 0.588681 2.35579 0.648909 2.38332 0.72037L2.77847 1.74787C2.78707 1.77032 2.80031 1.79071 2.81731 1.80771C2.83431 1.82471 2.85469 1.83794 2.87714 1.84654L3.90464 2.2417C3.96948 2.26666 4.02523 2.31069 4.06455 2.36796C4.10387 2.42524 4.12491 2.49308 4.12491 2.56256C4.12491 2.63203 4.10387 2.69988 4.06455 2.75715C4.02523 2.81443 3.96948 2.85845 3.90464 2.88342L2.87714 3.27857C2.85467 3.28714 2.83426 3.30036 2.81726 3.31736C2.80025 3.33437 2.78704 3.35477 2.77847 3.37724L2.38332 4.40475C2.35836 4.46959 2.31434 4.52534 2.25706 4.56467C2.19978 4.604 2.13194 4.62505 2.06246 4.62506Z"
        fill="#7C7D80"
      />
      <path
        d="M9.37494 6.50002C9.29915 6.49999 9.22514 6.477 9.16267 6.43408C9.10021 6.39115 9.05221 6.3303 9.02502 6.25955L8.48971 4.86806C8.48029 4.84355 8.46583 4.82128 8.44726 4.80271C8.42868 4.78413 8.40642 4.76967 8.3819 4.76025L6.99041 4.22494C6.91972 4.1977 6.85893 4.14969 6.81606 4.08722C6.77319 4.02476 6.75024 3.95078 6.75024 3.87502C6.75024 3.79926 6.77319 3.72527 6.81606 3.66281C6.85893 3.60035 6.91972 3.55234 6.99041 3.5251L8.3819 2.98978C8.40642 2.98037 8.42868 2.9659 8.44726 2.94733C8.46583 2.92876 8.48029 2.90649 8.48971 2.88197L9.02104 1.50033C9.04529 1.43499 9.0868 1.37745 9.14116 1.33383C9.19551 1.29021 9.26067 1.26214 9.32971 1.2526C9.41266 1.24255 9.4966 1.26051 9.56819 1.3036C9.63977 1.3467 9.69492 1.41248 9.72486 1.49049L10.2602 2.88197C10.2696 2.90649 10.2841 2.92876 10.3026 2.94733C10.3212 2.9659 10.3435 2.98037 10.368 2.98978L11.7595 3.5251C11.8302 3.55234 11.891 3.60035 11.9338 3.66281C11.9767 3.72527 11.9996 3.79926 11.9996 3.87502C11.9996 3.95078 11.9767 4.02476 11.9338 4.08722C11.891 4.14969 11.8302 4.1977 11.7595 4.22494L10.368 4.76025C10.3435 4.76967 10.3212 4.78413 10.3026 4.80271C10.2841 4.82128 10.2696 4.84355 10.2602 4.86806L9.72486 6.25955C9.69767 6.3303 9.64968 6.39115 9.58721 6.43408C9.52475 6.477 9.45074 6.49999 9.37494 6.50002Z"
        fill="#7C7D80"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_144">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ShareOfSearchIcon = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 1.49996H9.33333V0.666626C9.33333 0.389543 9.11042 0.166626 8.83333 0.166626H3.16667C2.88958 0.166626 2.66667 0.389543 2.66667 0.666626V1.49996H0.5C0.222917 1.49996 0 1.72288 0 1.99996V3.16663C0 3.91038 0.46875 4.67496 1.28958 5.26454C1.94583 5.73746 2.74375 6.03746 3.58125 6.13329C4.23542 7.21871 5 7.66663 5 7.66663V9.16663H4C3.26458 9.16663 2.66667 9.59788 2.66667 10.3333V10.5833C2.66667 10.7208 2.77917 10.8333 2.91667 10.8333H9.08333C9.22083 10.8333 9.33333 10.7208 9.33333 10.5833V10.3333C9.33333 9.59788 8.73542 9.16663 8 9.16663H7V7.66663C7 7.66663 7.76458 7.21871 8.41875 6.13329C9.25833 6.03746 10.0562 5.73746 10.7104 5.26454C11.5292 4.67496 12 3.91038 12 3.16663V1.99996C12 1.72288 11.7771 1.49996 11.5 1.49996ZM2.06875 4.18329C1.56042 3.81663 1.33333 3.40829 1.33333 3.16663V2.83329H2.67083C2.69167 3.51246 2.79167 4.10829 2.9375 4.62913C2.62292 4.52079 2.32917 4.37079 2.06875 4.18329ZM10.6667 3.16663C10.6667 3.50204 10.2979 3.91871 9.93125 4.18329C9.67083 4.37079 9.375 4.52079 9.06042 4.62913C9.20625 4.10829 9.30625 3.51246 9.32708 2.83329H10.6667V3.16663Z"
      fill="#7C7D80"
    />
  </svg>
);

export const SerpLocationChangerIcon = () => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.53753 12.2579C1.13211 7.32104 0.5 6.81437 0.5 5C0.5 2.51471 2.51471 0.5 5 0.5C7.48529 0.5 9.5 2.51471 9.5 5C9.5 6.81437 8.86789 7.32104 5.46247 12.2579C5.23899 12.5807 4.76098 12.5807 4.53753 12.2579ZM5 6.875C6.03554 6.875 6.875 6.03554 6.875 5C6.875 3.96446 6.03554 3.125 5 3.125C3.96446 3.125 3.125 3.96446 3.125 5C3.125 6.03554 3.96446 6.875 5 6.875Z"
      fill="#7C7D80"
    />
  </svg>
);

export const SerpVolatilityCheckerIcon = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.625 7.5H1.5V0.375C1.5 0.167812 1.33219 0 1.125 0H0.375C0.167812 0 0 0.167812 0 0.375V8.25C0 8.66414 0.335859 9 0.75 9H11.625C11.8322 9 12 8.83219 12 8.625V7.875C12 7.66781 11.8322 7.5 11.625 7.5ZM10.875 0.75H8.10797C7.60687 0.75 7.35586 1.35586 7.71023 1.71023L8.46961 2.46961L6.75 4.18945L5.03039 2.46984C4.73742 2.17688 4.26258 2.17688 3.96984 2.46984L2.35992 4.07977C2.21344 4.22625 2.21344 4.46367 2.35992 4.61016L2.89008 5.14031C3.03656 5.2868 3.27398 5.2868 3.42047 5.14031L4.5 4.06055L6.21961 5.78016C6.51258 6.07312 6.98742 6.07312 7.28016 5.78016L9.53016 3.53016L10.2895 4.28953C10.6439 4.64391 11.2498 4.39289 11.2498 3.8918V1.125C11.25 0.917812 11.0822 0.75 10.875 0.75Z"
      fill="#7C7D80"
    />
  </svg>
);

export const SeoBrowserExtensionIcon = () => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 3.875V1.25C6.5 0.835789 6.83579 0.5 7.25 0.5C7.66421 0.5 8 0.835789 8 1.25V3.875H6.5ZM9.125 4.25H0.875C0.667883 4.25 0.5 4.41788 0.5 4.625V5.375C0.5 5.58212 0.667883 5.75 0.875 5.75H1.25V6.5C1.25 8.3142 2.53834 9.82745 4.25 10.1749V12.5H5.75V10.1749C7.46166 9.82745 8.75 8.3142 8.75 6.5V5.75H9.125C9.33212 5.75 9.5 5.58212 9.5 5.375V4.625C9.5 4.41788 9.33212 4.25 9.125 4.25ZM3.5 3.875V1.25C3.5 0.835789 3.16421 0.5 2.75 0.5C2.33579 0.5 2 0.835789 2 1.25V3.875H3.5Z"
      fill="#7C7D80"
    />
  </svg>
);
