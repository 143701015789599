import React from 'react';
import {
  AISearchGraderIcon,
  RedditThreadsFinderIcon,
  SeoBrowserExtensionIcon,
  SerpLocationChangerIcon,
  SerpSimulatorIcon,
  SerpVolatilityCheckerIcon,
  ShareOfSearchIcon,
} from '../icons/freeToolsIcons';

export const MINI_SEO_TOOL_ITEMS = [
  {
    label: 'SERP Simulator',
    link: '/free-seo-tools/serp-simulator',
    icon: <SerpSimulatorIcon />,
    badge: 'TOP',
  },
  {
    label: 'Reddit Threads Finder',
    link: '/reddit-threads-finder',
    icon: <RedditThreadsFinderIcon />,
    badge: 'NEW',
  },
  {
    label: 'AI Search grader',
    link: '/ai-search-grader',
    icon: <AISearchGraderIcon />,
    badge: 'NEW',
  },
  {
    label: 'Share of search',
    link: '/free-seo-tools/share-of-search',
    icon: <ShareOfSearchIcon />,
  },
  {
    label: 'SERP Location changer',
    link: '/free-seo-tools/serp-location-changer',
    icon: <SerpLocationChangerIcon />,
  },
  {
    label: 'SERP Volatility checker',
    link: '/insights/serp',
    icon: <SerpVolatilityCheckerIcon />,
  },
  {
    label: 'SEO Browser extension',
    link: '/seo-extension',
    icon: <SeoBrowserExtensionIcon />,
  },
];
