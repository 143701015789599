import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/src/constants/Urls';
import { MINI_SEO_TOOL_ITEMS } from './MiniSeoToolsDropdown.constants';

export const MiniSeoToolListMobile = () => {
  return (
    <div>
      {MINI_SEO_TOOL_ITEMS.map((item) => {
        const url = `${Urls.LANDINGS_ROOT_HOST}${item.link}?ref=menu-app-sp`;
        return (
          <a
            key={item.label}
            className="mg-margin-b-10 uk-flex uk-flex-space-between uk-visible-hover"
            href={url}
            target="_blank"
            rel="noopener"
            style={{
              textDecoration: 'none',
            }}
          >
            <div className="font-14 uk-flex uk-flex-middle">
              <span
                className="uk-flex uk-flex-middle mg-margin-r-5"
                style={{ width: 15 }}
              >
                {item.icon}
              </span>
              <span style={{ textDecoration: 'underline', fontWeight: 700, fontFamily: 'Montserrat, Roboto' }}>
                {item.label}
              </span>
              {item.badge && (
                <strong className="mg-label is-yellow is-xsmall font-10 mg-margin-l-5">
                  {item.badge}
                </strong>
              )}
            </div>
            <span className="uk-hidden font-14">
              <FontAwesomeIcon icon="external-link-alt" aria-hidden="true" />
            </span>
          </a>
        );
      })}
    </div>
  );
};
