import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector, isSubUserSelector, userPlanTypeSelector } from 'selectors/userSelectors';
import { requestedNavigationAction } from 'actions/routerActions';
import Rollbar from 'mangools-commons/dist/configs/rollbar';
import { addCss, addJavascript } from 'components/layout/navbar/microAppLoaders/microAppLoaders.helpers';

export const OnboardingLoader = () => {
    const dispatch = useDispatch();
    const email = useSelector(state => state.user.email);
    const isSubUser = useSelector(isSubUserSelector);
    const planType = useSelector(userPlanTypeSelector);
    const token = useSelector(accessTokenSelector);

    const ONBOARDING_VERSION_SRC = `https://mangools-micro-frontend.s3.amazonaws.com/onboarding/${process.env.ONBOARDING_VERSION}`;
    // const ONBOARDING_VERSION_SRC_JS = `http://localhost:3000/static/js/bundle.js`;
    const ONBOARDING_VERSION_SRC_JS = `${ONBOARDING_VERSION_SRC}/main.js`;
    const ONBOARDING_VERSION_SRC_CSS = `${ONBOARDING_VERSION_SRC}/main.css`;

    useEffect(() => {
        if (email && token) {
            addCss(ONBOARDING_VERSION_SRC_CSS)
                .then(() => console.log('Onboarding CSS loaded'))
                .catch(error => {
                    console.error('Onboarding CSS loading error', error);
                    Rollbar.error('Onboarding CSS loading error', error);
                });

            addJavascript(ONBOARDING_VERSION_SRC_JS).then(() => {
                window.MangoolsOnboarding.initReactApp()
                    .then(() => {
                        window.MangoolsOnboarding.isInitialized
                            .then(() => {
                                window.MangoolsContext.setUser({
                                    email,
                                    isSubUser,
                                    plan: planType,
                                    token,
                                });
                                window.MangoolsOnboarding.onNavigate = (route, params) => {
                                    dispatch(requestedNavigationAction(route, params));
                                };
                            })
                            .catch(error => {
                                console.error('Onboarding initialization error', error);
                                Rollbar.error('Onboarding initialization error', error);
                            });
                    })
                    .catch(error => {
                        console.error('Onboarding init React App error', error);
                        Rollbar.error('Onboarding init React App error', error);
                    });
            });
        }
    }, [email, dispatch, isSubUser, planType, token]);

    return (
        <div
            id="onboarding"
            // data-source={'localhost'}
            data-source={process.env.MANGOOLS_APP_HOST === 'https://mangools.com' ? 'production' : 'beta'}
            data-theme-mode={document.body.classList.contains('dark-mode') ? 'dark' : 'light'}
        />
    );
};
