import { SourceService } from 'mangools-commons/dist/services/SourceService';
import HumanService from 'mangools-commons/dist/services/HumanService';

import { parse } from 'sources/parsers/KeywordsByDomainResultDataParser';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app';

const ENDPOINT = '/keywords';

class KeywordSource extends SourceService {
    static getData({ accessToken, keywordIds }) {
        const request = KeywordSource.createRequest({
            method: 'POST',
            parse: data => parse({ keywords: data }),
        });

        return request({
            requestURL: `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${ENDPOINT}`,
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: {
                fields: 'kw',
                keyword_ids: keywordIds,
            },
        });
    }
}

export { KeywordSource };
