export const SvgPlay = () => (
    <svg
        width='34'
        height='34'
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{
            paddingTop: 10
        }}
    >
        <g filter='url(#filter0_dd_952_2117)'>
            <rect x='5' y='1' width='24' height='24' rx='4' fill='white' />
            <path
                d='M13.2459 7.23337L13.469 7.68085C13.4981 7.66633 13.5315 7.66985 13.556 7.68831L13.5566 7.68879L20.5566 12.9388L20.5572 12.9392L20.5572 12.9392C20.5577 12.9396 20.562 12.9428 20.5671 12.9538C20.5725 12.9658 20.5771 12.9838 20.5771 13.0055C20.5771 13.0271 20.5725 13.0451 20.5671 13.0571C20.562 13.0681 20.5577 13.0713 20.5572 13.0717L20.5566 13.0721L13.5566 18.3221L13.556 18.3226C13.5316 18.3409 13.4985 18.3445 13.4695 18.3303C13.4407 18.3156 13.4233 18.2867 13.4233 18.2555L13.4233 7.75545C13.4233 7.72401 13.441 7.6949 13.47 7.68032L13.2459 7.23337ZM13.2459 7.23337C13.0481 7.33254 12.9233 7.53437 12.9233 7.75545L12.9233 18.2555C12.9233 18.4765 13.0481 18.6784 13.2459 18.7775L13.8566 7.28879C13.6799 7.15579 13.4436 7.13479 13.2459 7.23337Z'
                fill='#0521CC'
                stroke='#0521CC'
            />
        </g>
        <defs>
            <filter
                id='filter0_dd_952_2117'
                x='0'
                y='0'
                width='34'
                height='34'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feMorphology
                    radius='1'
                    operator='erode'
                    in='SourceAlpha'
                    result='effect1_dropShadow_952_2117'
                />
                <feOffset dy='2' />
                <feGaussianBlur stdDeviation='2' />
                <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
                <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_952_2117' />
                <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feMorphology
                    radius='1'
                    operator='erode'
                    in='SourceAlpha'
                    result='effect2_dropShadow_952_2117'
                />
                <feOffset dy='4' />
                <feGaussianBlur stdDeviation='3' />
                <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
                <feBlend
                    mode='normal'
                    in2='effect1_dropShadow_952_2117'
                    result='effect2_dropShadow_952_2117'
                />
                <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect2_dropShadow_952_2117'
                    result='shape'
                />
            </filter>
        </defs>
    </svg>
)