import React from 'react';
import { number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import RankColorizerService from 'mangools-commons/dist/services/RankColorizerService';

import Tooltip from 'components/other/Tooltip';
import DateFormatterService from 'services/DateFormatterService';
import { NEW_KD_ALGO_DEPLOY_TS, KD_CACHE_TIME_DAYS } from 'constants/Other';
import { getRelativeKD } from '@/utils/relativeKD';
import { RefreshIcon } from 'mangools-react-components/src/components/icons/refresh';

const KeywordDifficultyRank = ({
    rank,
    tooltipPlacement,
    updatedAt,
    loading,
    relativeKdActive,
    urlDataLps,
    onOutdatedRankClick
}) => {

    const isOutDatedRank = () => {
        if (isNil(updatedAt) || updatedAt === Defaults.NO_VALUE) {
            return true;
        } else {
            const rankUpdatedTs = updatedAt * 1000;
            const cacheValidTs = new Date().getTime() - KD_CACHE_TIME_DAYS * 24 * 60 * 60 * 1000;

            if (rankUpdatedTs < NEW_KD_ALGO_DEPLOY_TS || rankUpdatedTs < cacheValidTs) {
                // KD is either calculated before algo update or is older than 30 days (cache time)
                return true;
            } else {
                // KD is fresh
                return false;
            }
        }
    };

    const renderTooltip = () => {
        let dateString = '';
        if (isNil(updatedAt) || updatedAt === Defaults.NO_VALUE) {
            dateString = Defaults.NOT_AVAILABLE;
        } else {
            dateString = DateFormatterService.relativeAgoOrBasicYear(updatedAt * 1000);
        }

        return (
            <span>
                Last calculated: <strong>{dateString}</strong>
            </span>
        );
    };

    const renderRank = () => {
        const classes = classnames('mg-rank', RankColorizerService.getColor(rank));
        const isOutdated = isOutDatedRank();

        const handleRankClick = () => {
            if (isOutdated && onOutdatedRankClick) {
                onOutdatedRankClick();
            }
        }

        return (
            <Tooltip overlayContent={renderTooltip()} placement={tooltipPlacement}>
                <span className={classes} style={{ opacity: isOutdated ? 0.5 : 1.0 }} onClick={handleRankClick}>
                    {(onOutdatedRankClick && isOutdated) && (
                        <RefreshIcon
                            style={{
                                margin: '0 2px 2px 0'
                            }}
                        />
                    )}
                    <strong>{rank}</strong>
                    <FontAwesomeIcon icon="question-circle"/>
                </span>
            </Tooltip>
        );
    };

    const renderRelativeKdOrRank = () => {
        if (relativeKdActive) {
            if (
                rank !== Defaults.NO_VALUE ||
                rank !== Defaults.NOT_AVAILABLE ||
                urlDataLps !== Defaults.NO_VALUE ||
                urlDataLps !== Defaults.NOT_AVAILABLE
            ) {
                const classes = classnames('mg-rank', RankColorizerService.getColor(getRelativeKD(rank, urlDataLps)));
                return (
                    <Tooltip overlayContent={renderTooltip()} placement={tooltipPlacement}>
                        <span className={classes} style={{ opacity: isOutDatedRank() ? 0.5 : 1.0 }}>
                            <strong>{getRelativeKD(rank, urlDataLps)}</strong>
                        </span>
                    </Tooltip>
                );
            } else {
                return renderRank();
            }
        } else {
            return renderRank();
        }
    };

    const renderSearchIcon = () => {
        const classes = classnames('mg-rank', 'mg-no-rank');

        return (
            <span className={classes}>
                <FontAwesomeIcon icon="search" />
            </span>
        );
    };

    const renderLoading = () => {
        const classes = classnames('mg-rank', 'mg-no-rank');

        return (
            <span className={classes}>
                <span className="mg-preloader-block uk-width-2-3" />
            </span>
        );
    };

    if (loading) {
        return renderLoading();
    }

    return rank > 0 ? renderRelativeKdOrRank() : renderSearchIcon();
};

KeywordDifficultyRank.propTypes = {
    rank: number.isRequired,
    tooltipPlacement: string,
    updatedAt: number.isRequired,
    loading: bool,
    relativeKdActive: bool,
    urlDataLps: string,
};

export default KeywordDifficultyRank;
