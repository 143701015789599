import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import ColorSchemes from 'mangools-commons/dist/constants/ColorSchemes';
import hasPath from 'ramda/src/hasPath';

import ActionTypes from 'constants/ActionTypes';

const USD = {
    name: 'United States Dollar',
    countryCode: 'US',
    code: 'USD',
    symbol: '$',
    locale: 'en',
    rate: 1,
};

const initialState = {
    colorScheme: ColorSchemes.LIGHT,
    currency: USD,
    exporting: false,
    longerSerpLoadingNotificationVisibility: false,
    navigatedInternally: false,
    newVersionNotificationShown: false,
    onlineStatus: true,
};

const miscReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: {
            return update(state, {
                newVersionNotificationShown: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_MISC_EXPORTING_SET: {
            return update(state, {
                exporting: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET: {
            return update(state, {
                navigatedInternally: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_SHOW: {
            return update(state, {
                longerSerpLoadingNotificationVisibility: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_HIDE: {
            return update(state, {
                longerSerpLoadingNotificationVisibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_MISC_ONLINE_STATUS_SET: {
            return update(state, {
                onlineStatus: {
                    $set: action.payload.onlineStatus,
                },
            });
        }
        case ActionTypes.UI_MISC_COLOR_SCHEME_SET: {
            return update(state, {
                colorScheme: { $set: action.payload },
            });
        }
        case ActionTypes.UI_MISC_CURRENCY_SET: {
            return update(state, {
                currency: { $set: action.payload },
            });
        }
        case REHYDRATE: {
            return update(state, {
                colorScheme: {
                    $set: hasPath(['ui', 'misc', 'colorScheme'], action.payload)
                        ? action.payload.ui.misc.colorScheme
                        : initialState.colorScheme,
                },
                currency: {
                    $set: hasPath(['ui', 'misc', 'currency'], action.payload)
                        ? action.payload.ui.misc.currency
                        : initialState.currency,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default miscReducer;
