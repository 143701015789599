import React, { useRef, useState } from 'react';
import { func, number } from 'prop-types';
import { DEFAULT_LIST_KEYWORDS_MAX_LIMIT } from 'reducers/userReducer';
import NumberFormatterService from 'mangools-commons/dist/services/NumberFormatterService';
import { Input } from 'mangools-react-components/src';
import { useDispatch } from 'react-redux';

import { gtmTrack } from 'actions/analyticsActions';

import { analyticsEvents, analyticsActions } from 'constants/analytics';

const AddToListMessageNew = ({ onCreateNewList, addingKeywordCount }) => {
    const dispatch = useDispatch();

    const [listName, setListName] = useState('');
    const newListInputRef = useRef(null);

    const handleListNameChange = e => setListName(e.target.value);

    const handleAddToNewList = e => {
        e.preventDefault();

        dispatch(
            gtmTrack({
                action: analyticsActions.ADD_NEW,
                event: analyticsEvents.ADD_TO_LIST,
                keywordCount: addingKeywordCount,
            }),
        );
        onCreateNewList(listName);
    };

    const isOverLimit = () => addingKeywordCount > DEFAULT_LIST_KEYWORDS_MAX_LIMIT;

    const renderCannotAddMessage = () => {
        if (isOverLimit()) {
            return (
                <div className="mg-alert is-error uk-text-left font-14">
                    You can&apos;t exceed&nbsp;
                    <strong>{NumberFormatterService.simpleCommaNoDecimals(DEFAULT_LIST_KEYWORDS_MAX_LIMIT)}</strong>
                    &nbsp;keywords limit per list.
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="animated-once fade-in-up uk-text-left">
            <form onSubmit={handleAddToNewList}>
                <div className="mg-margin-b-15">
                    <Input
                        autoFocus
                        className="mg-input"
                        minLength={2}
                        maxLength={100}
                        onChange={handleListNameChange}
                        placeholder="e.g. Black coffee blog post 2020"
                        inputRef={newListInputRef}
                        required
                        spellCheck={false}
                        type="text"
                        value={listName}
                    />
                </div>
                <div>
                    {renderCannotAddMessage()}
                    <button className="mg-btn is-green uk-width-1-1" disabled={isOverLimit()} type="submit">
                        Create list &amp; Add keywords
                    </button>
                </div>
            </form>
        </div>
    );
};

AddToListMessageNew.propTypes = {
    addingKeywordCount: number.isRequired,
    onCreateNewList: func.isRequired,
};

export default AddToListMessageNew;
