import React from 'react';
import Urls from 'mangools-commons/src/constants/Urls';

type MiniSeoToolsDropdownItemProps = {
  label: string;
  icon: React.ReactNode;
  link: string;
  badge?: string;
};

export const MiniSeoToolsDropdownItem = ({
  label,
  icon,
  link,
  badge,
}: MiniSeoToolsDropdownItemProps) => {
  const redirectUrl = `${Urls.LANDINGS_ROOT_HOST}${link}?ref=mini-tools-menu`;

  return (
    <li>
      <a href={redirectUrl} className="mini-seo-tools-dropdown-item-link">
        <span className="mini-seo-tools-dropdown-item-span">
          <span className="mini-seo-tools-dropdown-icon-wrapper">{icon}</span>
          <span className="mini-seo-tools-dropdown-label">{label}</span>
        </span>
        {badge && <span className="mini-seo-tools-dropdown-badge">{badge}</span>}
      </a>
    </li>
  );
};
