import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colorSchemeSelector } from 'selectors/uiSelectors';
import DOMService from 'mangools-commons/dist/services/DOMService';
import { SvgPlay } from 'components/other/svgs/SvgPlay';

export const OnboardingButton = () => {
    const colorScheme = useSelector(colorSchemeSelector);
    const [isOnboardingTourCompleted, setIsOnboardingTourCompleted] = useState(false);

    const buttonStyles = {
        background: 'transparent',
        height: '49px',
        padding: '0 15px',
        width: '280px',
        fontFamily: 'Roboto, sans-serif',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const itemStyles = {
        fontSize: '14px',
    };

    const stateStyles = {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '12px',
        color: DOMService.isDarkMode(colorScheme) ? '#fff' : '#777',
    };

    const handleStartOnboarding = () => {
        window.dispatchEvent(new CustomEvent('startOnboarding'));
    };

    useEffect(() => {
        const handleOnboardingUpdated = (event) => {
            if (event.detail && event.detail.isCompleted !== undefined) {
                setIsOnboardingTourCompleted(event.detail.isCompleted);
            }
        };

        window.addEventListener('onboardingUpdated', handleOnboardingUpdated);

        return () => {
            window.removeEventListener('onboardingUpdated', handleOnboardingUpdated);
        };
    }, []);

    return (
        <div style={buttonStyles} onClick={handleStartOnboarding}>
            <div className="onboarding-tour"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingTop: 40,
                    marginTop: -40,
            }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={itemStyles}>Find keywords ideas</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isOnboardingTourCompleted && <div style={stateStyles}>Complete</div>}
                    <div>
                        <SvgPlay />
                    </div>
                </div>
            </div>
        </div>
    );
};
