import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { func, number, string } from 'prop-types';

import RouterService from 'services/RouterService';
import RoutePaths from 'constants/RoutePaths';
import { KeywordGapButton } from 'components/other/KeywordGapButton';

function MenuLeft(props) {
    return (
        <nav className="uk-flex uk-flex-middle">
            <ul className="mg-nav is-app uk-flex-inline">
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(props.currentRoute, RoutePaths.ROOT),
                    })}
                >
                    <Link to={RoutePaths.ROOT}>Search</Link>
                </li>
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(props.currentRoute, RoutePaths.IMPORT),
                    })}
                >
                    <Link to={RoutePaths.IMPORT}>Import</Link>
                </li>
                <li>
                    <button onClick={props.onShowListPanel} type="button">
                        <span>Lists</span>
                        <sup className="mg-margin-l-5 font-10 uk-hidden-small">{props.listCount}</sup>
                    </button>
                </li>
                <li>
                    <button onClick={props.onShowHistoryPanel} type="button">
                        History
                    </button>
                </li>
                <li>
                    <KeywordGapButton />
                </li>
            </ul>
        </nav>
    );
}

MenuLeft.propTypes = {
    currentRoute: string.isRequired,
    listCount: number.isRequired,
    onShowAppPanel: func.isRequired,
    onShowHistoryPanel: func.isRequired,
    onShowListPanel: func.isRequired,
};

export default MenuLeft;
