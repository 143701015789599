export const analyticsActions = {
    CLOSE_ALL: 'close_all',
    ADD_TO_LIST: 'add_to_list',
    KW_TO_SCH: 'kw->sch',
    KW_TO_SW: 'kw->sw',
    SEARCH_VOLUME: 'search_volume',
    CURRENCY_CHANGE: 'currency_change',
    CLICK: 'click',
    SUBMIT: 'submit',
    CURRENCY_MESSAGE: 'currency_message',
    SELECT_ALL_KEYWORDS: 'select_all_keywords',
    COPY_KEYWORDS: 'copy_keywords',
    EXPORT_KEYWORDS: 'export_keywords',
    SELECT_KEYWORD_TOGGLE: 'select_keyword_toggle',
    ENTER: 'enter',
    SHOW_SHORTCUTS_TOGGLE: 'show_shortcuts_toggle',
    EXPORT: 'export',
    SHOW: 'show',
    CLOSE: 'close',
    CONFIRM: 'confirm',
    LOGIN: 'login',
    REGISTER: 'register',
    ADD_EXISTING: 'add_existing',
    ADD_NEW: 'add_new',
    SHIFT_SELECT_KEYWORD_TOGGLE: 'shift_select_keyword_toggle',
    SEARCH: 'search',
    SELECTED_KEYWORDS_DELETE: 'selected_keywords_delete',
    TRACK_IN_SW_ADD_EXISTING: 'add_existing',
    TRACK_IN_SW_ADD_NEW: 'add_new',
};

export const analyticsEvents = {
    OUT_OF_LIMITS_MESSAGE: 'out_of_limits_message',
    LOGIN_MESSAGE: 'login_message',
    THEME_TOGGLE: 'theme_toggle',
    SCH_LINK: 'sch_link',
    TRACK_IN_SW: 'track_in_sw',
    SEARCH_VOLUME_CHANGE: 'search_volume_change',
    CURRENCY_CHANGE: 'currency_change',
    LOAD_NEXT_SERP: 'load_next_serp',
    IMPORT_KEYWORDS: 'import_keywords',
    SE_RANKING_IMPORT: 'serpwarcher_import_positions_history',
    ADD_TO_LIST: 'add_to_list',
    KB_SHORTCUT: 'kb_shortcut',
    BULK_CHECK_PROCEED: 'bulk_check_proceed',
    SORT_COLUMN: 'kw_sort_column',
    SUBMIT_RELATIVE_KD: 'submit_relative_kd',
    RECENT_RELATIVE_KD: 'recent_relative_kd',
    OPEN_RELATIVE_KD: 'recent_relative_kd',
    KW_TRY_TO_MOVE_KEYWORDS: 'kw_try_to_move_keywords',
    KW_COLUMNS_SELECTOR: 'kw_keywords_selector_clicked',
    SERP_COLUMNS_SELECTOR: 'kw_serp_selector_clicked',
    KW_COLUMNS_HIDE: 'kw_keywords_сolumn_hidden',
    KW_COLUMNS_SHOW: 'kw_keywords_сolumn_shown',
    SERP_COLUMNS_HIDE: 'kw_serp_column_hidden',
    SERP_COLUMNS_SHOW: 'kw_serp_column_shown',
    KW_CONTENT_TYPE_REQUESTED: 'kw_content_type_requested',
    KW_CONTENT_FILTER_USED: 'kw_content_filter_used',
    KW_SEARCH_INTENT_REQUESTED: 'kw_intent_requested',
    KW_SEARCH_INTENT_FILTER_USED: 'kw_intent_filter_used',
    KEYWORD_GAP_MESSAGE: 'keyword_gap_message',
    KEYWORD_GAP_MESSAGE_LINK: 'keyword_gap_message_link',
    KW_OUTDATED_RANK_REFRESH: 'kd_outdated_clicked',
};
