import update from 'immutability-helper';

import { sub, isAfter, lastDayOfMonth } from 'date-fns';
import Defaults from 'mangools-commons/dist/constants/Defaults';

import reduce from 'ramda/src/reduce';
import takeLast from 'ramda/src/takeLast';
import pipe from 'ramda/src/pipe';
import map from 'ramda/src/map';
import reverse from 'ramda/src/reverse';

import searchVolumeTimeframeTypes from 'constants/SearchVolumeTimeframeTypes';

import { SearchVolumeService } from 'services/SearchVolumeService';

const { LAST_12_MONTHS, LAST_6_MONTHS, LAST_3_MONTHS, LAST_KNOWN_VALUES } = searchVolumeTimeframeTypes;

class ResultDataService {
    /* eslint-disable no-shadow */
    static calculateSearchVolumeTimeframes(data) {
        const currentDate = new Date();

        return map(({ searchCount, ...resultRest }) => {
            const searchVolumes = pipe(
                takeLast(12),
                reverse,
                reduce(
                    (acc, msv) => {
                        const msvDate = new Date(
                            msv.year,
                            msv.month,
                            lastDayOfMonth(new Date(msv.year, msv.month)).getDate(),
                        );

                        const isLast6m = isAfter(
                            msvDate,
                            sub(currentDate, { months: 6, days: currentDate.getUTCDate() }),
                        );
                        const isLast3m = isAfter(
                            msvDate,
                            sub(currentDate, { months: 3, days: currentDate.getUTCDate() }),
                        );

                        const last6m = isLast6m
                            ? {
                                entriesCount: acc[LAST_6_MONTHS].entriesCount + 1,
                                sum: acc[LAST_6_MONTHS].sum + msv.count,
                            }
                            : acc[LAST_6_MONTHS];
                        const last3m = isLast3m
                            ? {
                                entriesCount: acc[LAST_3_MONTHS].entriesCount + 1,
                                sum: acc[LAST_3_MONTHS].sum + msv.count,
                            }
                            : acc[LAST_3_MONTHS];
                        const lastKnown = {
                            entriesCount: acc[LAST_KNOWN_VALUES].entriesCount + 1,
                            sum: acc[LAST_KNOWN_VALUES].sum + msv.count,
                        };

                        return {
                            [LAST_KNOWN_VALUES]: lastKnown,
                            [LAST_6_MONTHS]: last6m,
                            [LAST_3_MONTHS]: last3m,
                        };
                    },
                    {
                        [LAST_KNOWN_VALUES]: { entriesCount: 0, sum: 0 },
                        [LAST_6_MONTHS]: { entriesCount: 0, sum: 0 },
                        [LAST_3_MONTHS]: { entriesCount: 0, sum: 0 },
                    },
                ),
                result => {
                    return update(result, {
                        [LAST_12_MONTHS]: { $set: searchCount },
                        [LAST_KNOWN_VALUES]: {
                            $apply: data => {
                                if (data.sum === 0) {
                                    return Defaults.NO_VALUE;
                                } else {
                                    return SearchVolumeService.roundAverage(Math.floor(data.sum / data.entriesCount));
                                }
                            },
                        },
                        [LAST_6_MONTHS]: {
                            $apply: data => {
                                if (data.sum === 0) {
                                    return Defaults.NO_VALUE;
                                } else {
                                    return SearchVolumeService.roundAverage(Math.floor(data.sum / data.entriesCount));
                                }
                            },
                        },
                        [LAST_3_MONTHS]: {
                            $apply: data => {
                                if (data.sum === 0) {
                                    return Defaults.NO_VALUE;
                                } else {
                                    return SearchVolumeService.roundAverage(Math.floor(data.sum / data.entriesCount));
                                }
                            },
                        },
                    });
                },
            )(resultRest.monthlySearchVolumes);

            return update(resultRest, {
                searchVolumesByTimeframe: { $set: searchVolumes },
            });
        }, data);
    }

    static calculateSearchVolumeTimeframesForCompetitor(data) {
        return map(({ searchCount, ...resultRest }) => {
            return update(resultRest, {
                searchVolumesByTimeframe: {
                    $set: {
                        [LAST_KNOWN_VALUES]: Defaults.NO_VALUE,
                        [LAST_12_MONTHS]: searchCount,
                        [LAST_6_MONTHS]: Defaults.NO_VALUE,
                        [LAST_3_MONTHS]: Defaults.NO_VALUE,
                    },
                },
            });
        }, data);
    }
}

export default ResultDataService;
