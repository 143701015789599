import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorSchemes from 'mangools-commons/dist/constants/ColorSchemes';
import DOMService from 'mangools-commons/dist/services/DOMService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

const AUTO_THEME = 'Auto';
const LOCAL_STORAGE_KEY = 'mangools-color-scheme';

type SwitchMapProps = {
  [key: string]: {
    next: () => void;
    icon: IconProp;
    title: string;
    text: string;
  };
};

const colorSchemeSelector = (state: any) => state.ui.misc.colorScheme;

const setColorScheme = (payload: any) => ({
  type: 'UI_MISC_COLOR_SCHEME_SET',
  payload,
});

const DarkModeToggle = () => {
  const dispatch = useDispatch();
  const colorScheme = useSelector(state => colorSchemeSelector(state));
  const hasMatchMedia = DOMService.hasMatchMedia();

  useEffect(() => {
    const savedColorScheme = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedColorScheme) {
      dispatch(setColorScheme(savedColorScheme));
    }
  }, [dispatch]);

  const saveColorSchemeToLocalStorage = (scheme: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, scheme);
  };

  const switchMap = {
    [ColorSchemes.LIGHT]: {
      next: () => {
        dispatch(setColorScheme(ColorSchemes.DARK));
        saveColorSchemeToLocalStorage(ColorSchemes.DARK);
      },
      icon: 'sun',
      title: 'Switch to dark theme',
      text: 'Light',
    },
    [ColorSchemes.DARK]: {
      next: hasMatchMedia
        ? () => {
          dispatch(setColorScheme(ColorSchemes.AUTO));
          saveColorSchemeToLocalStorage(ColorSchemes.AUTO);
        }
        : () => {
          dispatch(setColorScheme(ColorSchemes.LIGHT));
          saveColorSchemeToLocalStorage(ColorSchemes.LIGHT);
        },
      icon: 'moon',
      title: `Switch to ${hasMatchMedia ? 'auto' : 'light theme'}`,
      text: 'Dark',
    },
    [ColorSchemes.AUTO]: {
      next: () => {
        dispatch(setColorScheme(ColorSchemes.LIGHT));
        saveColorSchemeToLocalStorage(ColorSchemes.LIGHT);
      },
      icon: ['fac', 'moon-auto'],
      title: 'Switch to light theme',
      text: AUTO_THEME,
    },
  } as SwitchMapProps;

  return (
    <button
      type="button"
      onClick={switchMap[colorScheme].next}
      title={switchMap[colorScheme].title}
    >
      <FontAwesomeIcon
        icon={switchMap[colorScheme].icon}
        aria-label="Theme toggle"
        className="uk-position-relative"
        style={switchMap[colorScheme].text === AUTO_THEME ? { left: '-2px' } : undefined}
      />
    </button>
  );
};

export default DarkModeToggle;
