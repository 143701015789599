import React, { useState } from 'react';
import Dropdown from 'rc-dropdown';
import { MINI_SEO_TOOL_ITEMS } from './miniSeoTools/MiniSeoToolsDropdown.constants';
import { MiniSeoToolsDropdownItem } from './miniSeoTools/MiniSeoToolsDropdownItem';

export const MiniSeoToolsDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menu = (
    <ul className="mini-seo-tools-dropdown-menu">
      {MINI_SEO_TOOL_ITEMS.map((item) => (
        <MiniSeoToolsDropdownItem key={item.label} {...item} />
      ))}
    </ul>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={menu}
      visible={isOpen}
      animation='slide-up'
      onVisibleChange={(visible) => setIsOpen(visible)}
      overlayStyle={{ zIndex: 8000 }}
      placement='bottomRight'
      align={{ offset: [0, 0] }}
    >
      <button className="mini-seo-tools-dropdown-button uk-hidden-small">
        Mini SEO tools
        <div className="mini-seo-tools-dropdown-dot"/>
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
        >
          <path
            d="M3.73483 4.46053L0.698219 1.4239C0.551766 1.27745 0.551766 1.04001 0.698219 0.893573L1.05239 0.539402C1.19859 0.393199 1.43555 0.392917 1.58209 0.538777L4 2.94535L6.41789 0.538777C6.56444 0.392917 6.80139 0.393199 6.94759 0.539402L7.30177 0.893573C7.44822 1.04003 7.44822 1.27746 7.30177 1.4239L4.26517 4.46053C4.11872 4.60696 3.88128 4.60696 3.73483 4.46053Z"
            fill="#c0c0c1"
          />
        </svg>
      </button>
    </Dropdown>
  );
};
