import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DarkModeToggle } from 'mangools-react-components/src';
import { HelpDropdown } from 'mangools-react-components/src/components/helpDropsown/HelpDropdown';
import { HELP_DROPDOWN_ITEMS } from 'constants/HelpDropdown';
import { OnboardingLoader } from 'components/layout/navbar/microAppLoaders/OnboardingLoader';

class MenuRight extends Component {
    constructor() {
        super();

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleHelpDropdownClick = this.handleHelpDropdownClick.bind(this);

        // Dropdown containers
        this.helpDropdownContainer = null;
    }

    componentDidMount() {
        window.APP_ROOT.addEventListener('click', this.handleDocumentClick);
    }

    componentWillUnmount() {
        window.APP_ROOT.removeEventListener('click', this.handleDocumentClick);
    }

    handleDocumentClick(e) {
        if (
            this.props.helpDropdownVisible &&
            this.helpDropdownContainer &&
            !this.helpDropdownContainer.contains(e.target)
        ) {
            this.props.onCloseHelpDropdown();
        }
    }

    handleHelpDropdownClick(_e) {
        if (this.props.helpDropdownVisible === true) {
            this.props.onCloseHelpDropdown();
        } else {
            this.props.onShowHelpDropdown();
        }
    }

    render() {
        return (
            <nav className="uk-margin-remove uk-padding-remove uk-flex uk-flex-middle uk-hidden-small">
                <ul className="mg-nav uk-flex-inline">
                    <li>
                        <DarkModeToggle />
                    </li>
                    <li className="uk-hidden-touch">
                        <button onClick={this.props.onShowShortcutsMessage} type="button">
                            <FontAwesomeIcon icon="keyboard" aria-label="Keyboard shortcuts" />
                        </button>
                    </li>
                    <li>
                        <HelpDropdown itemGroups={HELP_DROPDOWN_ITEMS} />
                        <OnboardingLoader />
                    </li>
                </ul>
            </nav>
        );
    }
}

MenuRight.propTypes = {
    helpDropdownVisible: bool.isRequired,
    onCloseHelpDropdown: func.isRequired,
    onShowHelpDropdown: func.isRequired,
    onShowShortcutsMessage: func.isRequired,
};

export default MenuRight;
