// Need to be destructed separated. Otherwise it will not load `process` properly
const { APPS_ROOT_HOST } = process.env;
const { LANDINGS_ROOT_HOST } = process.env;
const { MANGOOLS_APP_HOST } = process.env;

if (APPS_ROOT_HOST === undefined) {
    throw new Error('Missing env APPS_ROOT_HOST');
}
if (LANDINGS_ROOT_HOST === undefined) {
    throw new Error('Missing env LANDINGS_ROOT_HOST');
}
if (!MANGOOLS_APP_HOST === undefined) {
    throw new Error('Missing env MANGOOLS_APP_HOST');
}

// NOTE: Legacy URLS, add new paths without the HOST url in MANGOOLS_PATHS, KWFINDER_PATHS, ... below
const URLS = {
    MANGOOLS_APP_HOST,
    LANDINGS_ROOT_HOST,
    FEEDBACK_FORM_URL: `https://docs.google.com/forms/d/1efxd3IP_-00UJpYrDs-OA52TXdyYn0SVy6BcGZvRIdA/viewform`,
    GRAVATAR_DEFAULT_IMAGE_ENCODED_URL: `https%3A%2F%2Fmangools.com%2Fgravatar.png`,
    GRAVATAR_DEFAULT_IMAGE_URL: `${MANGOOLS_APP_HOST}/gravatar.png`,
    GRAVATAR_URL: `https://secure.gravatar.com/avatar/`,

    KWFINDER_APP_URL: `${APPS_ROOT_HOST}/kwfinder`,
    KWFINDER_LANDING_URL: `${LANDINGS_ROOT_HOST}/kwfinder`,
    KWFINDER_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/kwfinder-guide/`,

    LINKMINER_APP_URL: `${APPS_ROOT_HOST}/linkminer`,
    LINKMINER_LANDING_URL: `${LANDINGS_ROOT_HOST}/linkminer`,
    LINKMINER_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/linkminer-guide/`,

    SERPCHECKER_APP_URL: `${APPS_ROOT_HOST}/serpchecker`,
    SERPCHECKER_LANDING_URL: `${LANDINGS_ROOT_HOST}/serpchecker`,
    SERPCHECKER_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/serpchecker-guide/`,

    SERPWATCHER_APP_URL: `${APPS_ROOT_HOST}/serpwatcher`,
    SERPWATCHER_LANDING_URL: `${LANDINGS_ROOT_HOST}/serpwatcher`,
    SERPWATCHER_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/serpwatcher-guide/`,

    SITEPROFILER_APP_URL: `${APPS_ROOT_HOST}/siteprofiler`,
    SITEPROFILER_LANDING_URL: `${LANDINGS_ROOT_HOST}/siteprofiler`,
    SITEPROFILER_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/siteprofiler-guide/`,

    CONTENTGENERATOR_APP_URL: `${APPS_ROOT_HOST}/ai-content`,
    CONTENTGENERATOR_LANDING_URL: `${LANDINGS_ROOT_HOST}/ai-content`,
    CONTENTGENERATOR_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/ai-content-guide/`,

    MANGOOLS_60_LINKBUILDING_TECHNIQUES_URL: `${MANGOOLS_APP_HOST}/blog/link-building-techniques-checklist/`,
    MANGOOLS_ABOUT_US_URL: `${MANGOOLS_APP_HOST}/about-us`,
    MANGOOLS_ACCOUNT_URL: `${MANGOOLS_APP_HOST}/account`,
    MANGOOLS_AFFILIATE_URL: `${MANGOOLS_APP_HOST}/affiliate-program`,
    MANGOOLS_BILLING_URL: `${MANGOOLS_APP_HOST}/billing`,
    MANGOOLS_BLOG_URL: `${MANGOOLS_APP_HOST}/blog/`,
    MANGOOLS_CHANGE_PASSWORD_URL: `${MANGOOLS_APP_HOST}/apps#password`,
    MANGOOLS_CONTACT_URL: `${MANGOOLS_APP_HOST}/contact`,
    MANGOOLS_DASHBOARD_URL: `${MANGOOLS_APP_HOST}/apps`,
    MANGOOLS_EXTENSION_URL: `${MANGOOLS_APP_HOST}/seo-extension/`,
    MANGOOLS_FAQ_URL: `${MANGOOLS_APP_HOST}/faq`,
    MANGOOLS_INVOICES_URL: `${MANGOOLS_APP_HOST}/payments`,
    MANGOOLS_KEYWORD_RESEARCH_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/keyword-research/`,
    MANGOOLS_LOGIN_NO_REDIRECT_URL: `${MANGOOLS_APP_HOST}/users/sign_in`,
    MANGOOLS_LOGIN_URL: `${MANGOOLS_APP_HOST}/users/sign_in?redirect=true`,
    MANGOOLS_ON_PAGE_SEO_GUIDE_URL: `${MANGOOLS_APP_HOST}/blog/on-page-seo/`,
    MANGOOLS_PLANS_AND_PRICING_URL: `${MANGOOLS_APP_HOST}/plans-and-pricing`,
    MANGOOLS_PLANS_URL: `${MANGOOLS_APP_HOST}/plans`,
    MANGOOLS_PROXY_URL: `https://icon.mnglsapi.com`,
    MANGOOLS_REGISTER_NO_REDIRECT_URL: `${MANGOOLS_APP_HOST}/users/sign_up`,
    MANGOOLS_REGISTER_URL: `${MANGOOLS_APP_HOST}/users/sign_up?redirect=true`,
    MANGOOLS_ROOT_URL: `${MANGOOLS_APP_HOST}/`, // Deprecated: delete when is no more used in the apps
    MANGOOLS_APP_URL: `${MANGOOLS_APP_HOST}`,
    MANGOOLS_SEO_ACADEMY_URL: `${MANGOOLS_APP_HOST}/blog/learn-seo/`,
    MANGOOLS_SERP_INSIGHTS_URL: `${MANGOOLS_APP_HOST}/insights/serp`,
    MANGOOLS_SERP_SIMULATOR_URL: `${MANGOOLS_APP_HOST}/free-seo-tools/serp-simulator`,
    MANGOOLS_SUBSCRIPTIONS_URL: `${MANGOOLS_APP_HOST}/subscriptions`,
    MANGOOLS_TESTIMONIALS_URL: `${MANGOOLS_APP_HOST}/testimonials`,

    SNAPSHOT_BASE_URL: `https://s3.amazonaws.com/mangools-google-pages`,
    SNAPSHOT_BETA_BASE_URL: `https://s3.amazonaws.com/mangools-google-pages-test`,
};

export default URLS;

export const MANGOOLS_PATHS = {
    GRAVATAR_DEFAULT_IMAGE: 'gravatar.png',
    KWFINDER_GUIDE: 'blog/kwfinder-guide/',
    LINKMINER_GUIDE: 'blog/linkminer-guide/',
    LINKBUILDING_TECHNIQUES: 'blog/link-building-techniques-checklist/',
    ABOUT_US: 'about-us',
    ACCOUNT: 'account',
    AFFILIATE: 'affiliate-program',
    BILLING: 'billing',
    BLOG: 'blog/',
    CHANGE_PASSWORD: 'apps#password',
    CONTACT: 'contact',
    DASHBOARD: 'apps',
    EXTENSION: 'seo-extension/',
    FAQ: 'faq',
    INVOICES: 'payments',
    KEYWORD_RESEARCH_GUIDE: 'blog/keyword-research/',
    LOGIN_NO_REDIRECT: 'users/sign_in',
    LOGIN: 'users/sign_in?redirect=true',
    ON_PAGE_SEO_GUIDE: 'blog/on-page-seo/',
    PLANS_AND_PRICING: 'plans-and-pricing',
    PLANS: 'plans',
    REGISTER_NO_REDIRECT: 'users/sign_up',
    REGISTER: 'users/sign_up?redirect=true',
    ROOT: '',
    SEO_ACADEMY: 'blog/learn-seo/',
    SERP_INSIGHTS: 'insights/serp',
    SERP_SIMULATOR: 'free-seo-tools/serp-simulator',
    SUBSCRIPTIONS: 'subscriptions',
    TESTIMONIALS: 'testimonials',
    SERPCHECKER_GUIDE: 'blog/serpchecker-guide/',
    SERPWATCHER_GUIDE: 'blog/serpwatcher-guide/',
    SITEPROFILER_GUIDE: 'blog/siteprofiler-guide/',
};

export const SERPWATCHER_PATHS = {
    // TODO
};

export const KWFINDER_PATHS = {
    // TODO
};

export const SERPCHECKER_PATHS = {
    // TODO
};

export const LINKMINER_PATHS = {
    // TODO
};

export const SITEPROFILER_PATHS = {
    // TODO
};

export const CONTENTGENERATOR_PATHS = {
    // TODO
};
