import { call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import DOMService from 'mangools-commons/dist/services/DOMService';
import AuthService from 'mangools-commons/dist/services/AuthService';
import UsercomService from 'mangools-commons/dist/services/UsercomService';

import { setAuthTokens, skippedUserAction } from 'actions/userActions';
import { requestedHideAuthTokensAction } from 'actions/routerActions';

import { setNavigatedInternally, showNeedToSignInMessage } from 'actions/uiActions';

import { currentQuerySelector } from 'selectors/routingSelectors';

import {
    accessTokenSelector,
    idSelector,
    loginTokenSelector,
    ssoTicketSelector,
    userPlanTypeSelector,
} from 'selectors/userSelectors';

import { colorSchemeSelector } from 'selectors/uiSelectors';

import { fetchLimitData, fetchUserData } from 'sagas/userSagas';
import { fetchAfterLoginData } from 'sagas/dataSagas';
import { initAnalytics } from 'sagas/analyticsSagas';
import { redirectToAuth } from 'sagas/routerSagas';

import { cleanPreviousState } from 'lib/store';

import ActionTypes from 'constants/ActionTypes';
import UserPlanTypes from 'constants/UserPlanTypes';

/**
 * App Init flow sagas.
 */

function* afterUserFlow() {
    // 5. Fetch user limits
    yield spawn(fetchLimitData);

    // 6. Get email and initialize chat widget
    const userId = yield select(idSelector);

    yield spawn(UsercomService.initChatWidet, {
        userId,
        apiKey: process.env.USERCOM_CHAT_WIDGET_API_KEY,
        subdomain: process.env.USER_COM_SUBDOMAIN,
    });

    // 7. Setup analytics stuff
    yield spawn(initAnalytics);

    // 8. Now fetch remaining after login data (user's lists, history)
    // will be skipped if we dont have access token
    yield spawn(fetchAfterLoginData);

    // 9. Delete prev. version LocalStorage to keep it clean
    yield spawn(cleanPreviousState);

    // 10. If there are no token params in the query and accessToken is null it means
    // the /system/me endpoint returned null, thus our tokens are invalid and we need to redirect to mangools.com
    const query = yield select(currentQuerySelector);
    const accessToken = yield select(accessTokenSelector);
    const auth = new AuthService(query, { ssoTicket: null, loginToken: null });

    if (auth.hasInvalidStoredTokens(accessToken)) {
        yield call(redirectToAuth);
    }

    // 11. Get plan type and conditionally show sign in message
    const planType = yield select(userPlanTypeSelector);

    if (auth.hasEmptyQueryTokens() && planType === UserPlanTypes.NO_PLAN) {
        yield put(showNeedToSignInMessage());
    }

    // 12. Hiding `sso_ticket` and `login_token` params from URL for security reasons.
    // This is called every time, as it's non-descrutive even when no tokens are present.
    yield put(requestedHideAuthTokensAction());
}

function* watchUserFetched() {
    yield takeLatest(ActionTypes.DATA_USER_DATA_RECEIVED, afterUserFlow);
}

function* fetchUser() {
    yield spawn(watchUserFetched);
    yield call(fetchUserData, null, false);
}

function* authFlow() {
    const query = yield select(currentQuerySelector);
    const ssoTicket = yield select(ssoTicketSelector);
    const loginToken = yield select(loginTokenSelector);

    const auth = new AuthService(query, { ssoTicket, loginToken });

    if (auth.hasEmptyQueryTokens()) {
        // `sso_ticket` and `login_token` params are missing e.g. user is logged out
        // we want to show sign in message by calling fetchUserData which triggers afterUserFlow
        yield put(skippedUserAction());
        yield call(afterUserFlow);
    } else if (auth.hasQueryTokens()) {
        // Auth params are present, save them to store and fetch user
        yield put(
            setAuthTokens({
                ssoTicket: query.sso_ticket,
                loginToken: query.login_token,
            }),
        );

        yield call(fetchUser);
    } else if (!auth.hasStoredTokens()) {
        // Either ssoTicket or loginToken isn't present in the store, redirect to auth page
        yield call(redirectToAuth);
    } else if (auth.hasStoredTokens()) {
        // Auth tokens are both present in the store, fetch the user
        yield call(fetchUser);
    }
}

export function* initFlow() {
    const colorScheme = yield select(colorSchemeSelector);

    if (DOMService.isDarkMode(colorScheme)) {
        DOMService.darkModeOn();
    }

    yield call(authFlow);
}

/**
 * Internall navigation flow
 */
export function* internalNavigationFlow() {
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield put(setNavigatedInternally());
}
