import React, { ReactNode, useEffect, useState } from 'react';
import Dropdown from 'rc-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Item = {
  label: string;
  link: string;
  description?: string;
  element?: ReactNode;
};

type ItemGroup = {
  label: string;
  items: Item[];
};

type HelpDropdownProps = {
  itemGroups: ItemGroup[];
};

export const HelpDropdown = ({
  itemGroups,
}: HelpDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOpenDropdown = () => {
      setIsOpen(true);
    };
    window.addEventListener('openHelpDropdown', handleOpenDropdown);
    return () => {
      window.removeEventListener('openHelpDropdown', handleOpenDropdown);
    };
  }, [isOpen]);

  const menu = (
    <ul className="help-dropdown">
      {itemGroups.map((group) => (
        <React.Fragment key={group.label}>
          <li className="help-dropdown-group-label">{group.label}</li>
          {group.items.map((item, i) => {
            if (item.element) {
              return (
                <li className="help-dropdown-item-custom" key={i} onClick={() => setIsOpen(false)}>
                  {item.element}
                </li>
              );
            }

            return (
              <li className="help-dropdown-item" key={item.label}>
                <a
                  className="help-dropdown-item-link"
                  href={item.link}
                  target="_blank"
                  rel="noopener"
                >
                  <span>{item.label}</span>
                  {item.description && (
                    <span className="help-dropdown-item-description">
                      {item.description}
                    </span>
                  )}
                </a>
              </li>
            );
          })}
        </React.Fragment>
      ))}
    </ul>
  );

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={menu}
        visible={isOpen}
        animation="slide-up"
        onVisibleChange={(visible) => setIsOpen(visible)}
        overlayStyle={{ zIndex: 8000 }}
        placement="bottomRight"
        align={{ offset: [0, 0] }}
        getPopupContainer={() => document.body}
      >
        <button>
          Help
          <FontAwesomeIcon
            className="mg-margin-l-5 font-10"
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
          />
        </button>
      </Dropdown>
    </>
  );
};
