import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector, isSubUserSelector, userPlanTypeSelector } from 'selectors/userSelectors';
import { requestedLogoutAction } from 'actions/userActions';
import Rollbar from 'mangools-commons/dist/configs/rollbar';
import { addCss, addJavascript } from 'components/layout/navbar/microAppLoaders/microAppLoaders.helpers';

export const UserDropdownLoader = () => {
    const dispatch = useDispatch();
    const email = useSelector(state => state.user.email);
    const isSubUser = useSelector(isSubUserSelector);
    const planType = useSelector(userPlanTypeSelector);
    const token = useSelector(accessTokenSelector);

    const USER_DROPDOWN_VERSION_SRC = `https://mangools-micro-frontend.s3.amazonaws.com/user-dropdown/${process.env.USER_DROPDOWN_VERSION}`;
    const USER_DROPDOWN_VERSION_SRC_JS = `${USER_DROPDOWN_VERSION_SRC}/main.js`;
    const USER_DROPDOWN_VERSION_SRC_CSS = `${USER_DROPDOWN_VERSION_SRC}/main.css`;

    const onLogoutClick = () => {
        dispatch(requestedLogoutAction());
    };

    useEffect(() => {
        if (email) {
            addCss(USER_DROPDOWN_VERSION_SRC_CSS)
                .then(() => console.log('User dropdown CSS loaded'))
                .catch(error => {
                    console.error('User dropdown CSS loading error', error);
                    Rollbar.error('User dropdown CSS loading error', error);
                });

            addJavascript(USER_DROPDOWN_VERSION_SRC_JS).then(() => {
                window.MangoolsUserDropdown.initReactApp()
                    .then(() => {
                        window.MangoolsUserDropdown.isInitialized
                            .then(() => {
                                window.MangoolsContext.setUser({
                                    email,
                                    isSubUser,
                                    plan: planType,
                                    token,
                                });
                                window.MangoolsContext.onLogout = () => {
                                    onLogoutClick();
                                };
                            })
                            .catch(error => {
                                console.error('User dropdown initialization error', error);
                                Rollbar.error('User dropdown initialization error', error);
                            });
                    })
                    .catch(error => {
                        console.error('User dropdown init React App error', error);
                        Rollbar.error('User dropdown init React App error', error);
                    });
            });
        }
    }, [email, dispatch, isSubUser, planType, token]);

    return (
        <div id="user-dropdown"
             data-source={process.env.MANGOOLS_APP_HOST === 'https://mangools.com' ? 'production' : 'beta'}
             is-small="true"
             data-theme-mode={document.body.classList.contains('dark-mode') ? 'dark' : 'light'}
             data-tracking-ref="APP_KW"
        />
    );
};
