import { number, shape, string } from 'prop-types';

export default shape({
    name: string.isRequired,
    countryCode: string.isRequired,
    code: string.isRequired,
    symbol: string.isRequired,
    locale: string.isRequired,
    rate: number.isRequired,
});
