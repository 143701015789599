import Rollbar from 'rollbar';
import { APP_CONFIG } from './app';
export const IGNORED_MESSAGES = [`Your rate limit has been reached for access token "post_client_item".`, '(unknown): ResizeObserver loop limit exceeded', 'ResizeObserver loop limit exceeded'];

// Error handling using rollbar
// https://rollbar.com/docs/notifier/rollbar.js/configuration
const config = {
  accessToken: APP_CONFIG.ROLLBAR_API_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: ['mangools.com', 'mnglsbeta.com'],
  payload: {
    environment: APP_CONFIG.APP_ENV,
    client: {
      source_map_enabled: true,
      code_version: APP_CONFIG.APP_VERSION,
      guess_uncaught_frames: true
    }
  },
  ignoredMessages: IGNORED_MESSAGES
};
const rollbar = Rollbar.init(config);
export default rollbar;