import { eventChannel } from 'redux-saga';
import { call, delay, put, race, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import isNil from 'ramda/src/isNil';
import Paths from 'mangools-commons/dist/constants/Paths';
import Defaults from 'mangools-commons/dist/constants/Defaults';
import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/dist/constants/ErrorCodes';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app';

import RouterService from 'services/RouterService';
import { fetchAfterLoginData } from 'sagas/dataSagas';

import { accessTokenSelector, loggedInSelector, loginTokenSelector, ssoTicketSelector } from 'selectors/userSelectors';

import { onlineStatusSelector } from 'selectors/uiSelectors';

import UserSource from 'sources/UserSource';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';
import RoutePaths from 'constants/RoutePaths';

import {
    errorAction,
    errorCheckStatusAction,
    errorLimitsAction,
    errorLogoutAction,
    fetchingAction,
    fetchingLimitsAction,
    receivedAction,
    receivedLimitsAction,
    receivedLogoutAction,
    skippedCheckStatusAction,
    skippedLimitsAction,
} from 'actions/userActions';

import {
    closeNeedToSignInMessage,
    showFailureMessage,
    showLoggedOutMessage,
    showNeedToSignInMessage,
    showNoConnectionMessage,
} from 'actions/uiActions';

import { requestedNavigationAction } from 'actions/routerActions';

import { initAnalytics } from 'sagas/analyticsSagas';

import { handleUncaught, logError } from 'sagas/errorSagas';

const checkUserStatus = handleUncaught(function* checkUserStatus() {
    const online = yield select(onlineStatusSelector);
    const ssoTicket = yield select(ssoTicketSelector);
    const loginToken = yield select(loginTokenSelector);

    if (online === true && !isNil(ssoTicket) && !isNil(loginToken)) {
        const loggedIn = yield select(loggedInSelector);
        const { error, payload } = yield call(UserSource.getData, { ssoTicket, loginToken });

        if (!error) {
            if (isNil(payload.accessToken) && loggedIn) {
                // User is logged in but was logged out externally
                // So we make him logged out in this app as well
                yield put(receivedLogoutAction());
                yield put(requestedNavigationAction(RoutePaths.ROOT, {}));
                yield put(showNeedToSignInMessage());
                yield put(showLoggedOutMessage());
            } else if (!isNil(payload.accessToken) && !loggedIn) {
                // User is not logged in but was logged in externally
                // So we set him logged in and load his data in this app
                yield put(receivedAction(payload));
                yield call(initAnalytics);
                yield call(fetchAfterLoginData);
                yield put(closeNeedToSignInMessage());
            }
        } else {
            // Request failed due to network error, or other issue
            // Fail silently because otherwise it would show loader like
            // when user is first time loading the app. And the worst case
            // scenario (logged out externally but still logged in in app)
            // is not so dangerous anyway.
            yield put(errorCheckStatusAction(payload));
            // NOTE: dont logging error due to rollbar limits
            // yield call(logError, 'CheckUserStatusSaga', payload);
        }
    } else {
        yield put(skippedCheckStatusAction());
        // yield call(logInfo, 'CheckUserStatusSagaSkipped', { online, ssoTicket, loginToken });
    }
});

function userCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.USER_CHECK_INTERVAL);

        return () => {
            clearInterval(intervalId);
        };
    });
}

export const fetchUserData = handleUncaught(function* fetchUserData(retrying = false) {
    const ssoTicket = yield select(ssoTicketSelector);
    const loginToken = yield select(loginTokenSelector);
    yield put(fetchingAction());

    const { error, payload } = yield call(UserSource.getData, { ssoTicket, loginToken, disableCache: true });

    if (!error) {
        yield put(receivedAction(payload));
    } else if (retrying === true) {
        yield put(errorAction(payload));
        yield call(logError, 'FetchUserDataSaga', payload);
    } else {
        // Retry request
        yield call(fetchUserData, true);
    }

    return payload;
});

export const fetchLimitData = handleUncaught(
    function* fetchLimitData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingLimitsAction());

            const { result, _timeout } = yield race({
                result: call(UserSource.getLimitData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedLimitsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorLimitsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchLimitData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorLimitsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_limits_error }));
                                yield call(logError, 'FetchLimitDataSaga', payload);
                            } else {
                                yield call(fetchLimitData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorLimitsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_limits_error }));
                yield call(logError, 'FetchLimitDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedLimitsAction());
        }
    },
    function* onError() {
        yield put(errorLimitsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_limits_error }));
    },
);

const logoutUser = handleUncaught(
    function* logoutUser() {
        yield put(receivedLogoutAction());
        RouterService.redirect(`${APP_CONFIG.MANGOOLS_API_HOST}${Paths.MANGOOLS_LOGOUT_PATH}`);
    },
    function* onError() {
        yield put(errorLogoutAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.logout_error }));
    },
);

function* watchUserLoginStatusByInterval() {
    const channel = yield call(userCheckIntervalChannel);
    yield takeEvery(channel, checkUserStatus);
}

function* watchLimitRequests() {
    yield takeLatest(ActionTypes.DATA_USER_LIMIT_DATA_REQUESTED, fetchLimitData);
}

function* watchLogoutRequests() {
    yield takeLatest(ActionTypes.DATA_USER_LOGOUT_REQUESTED, logoutUser);
}

export function* watchUserRequests() {
    yield spawn(watchUserLoginStatusByInterval);
    yield spawn(watchLimitRequests);
    yield spawn(watchLogoutRequests);
}
