import React from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { MangoolsNavBar } from 'mangools-react-components/src';
import { LinkRefSources } from 'mangools-commons/dist/constants/LinkRefSources';
import DOMService from 'mangools-commons/dist/services/DOMService';

import mnglsImageWhite from 'images/mangools-logo-full-white.svg';

import {
    closeHelpDropdown,
    closeLinksDropdown,
    showAppPanel,
    showHelpDropdown,
    showHistoryPanel,
    showLinksDropdown,
    showListPanel,
    showShortcutsMessage,
} from 'actions/uiActions';

import MenuLeft from 'components/layout/navbar/MenuLeft';
import MenuRight from 'components/layout/navbar/MenuRight';
import { DarkModeToggle } from 'mangools-react-components/src';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import { UserDropdownLoader } from 'components/layout/navbar/microAppLoaders/UserDropdownLoader';
import { userPlanTypeSelector } from 'selectors/userSelectors';

function NavbarContainer({ currentRoute }) {
    const dispatch = useDispatch();
    const {
        email,
        helpDropdownVisible,
        linksDropdownVisible,
        listCount,
        loggedIn,
        planType,
        trackedKeywordLimit,
    } = useSelector(state => ({
        email: state.user.email,
        helpDropdownVisible: state.ui.dropdowns.help.visibility,
        linksDropdownVisible: state.ui.dropdowns.links.visibility,
        listCount: state.data.lists.count,
        loggedIn: state.user.loggedIn,
        planType: userPlanTypeSelector(state),
        trackedKeywordLimit: state.user.limits.trackedKeywordLimit,
    }));

    const onCloseHelpDropdown = () => {
        dispatch(closeHelpDropdown());
    };

    const onCloseLinksDropdown = () => {
        dispatch(closeLinksDropdown());
    };

    const onShowHelpDropdown = () => {
        dispatch(showHelpDropdown());
    };

    const onShowLinksDropdown = () => {
        dispatch(showLinksDropdown());
    };

    const onShowShortcutsMessage = () => {
        dispatch(showShortcutsMessage());
    };

    const onShowAppPanel = () => {
        dispatch(showAppPanel());
    };

    const onShowListPanel = () => {
        dispatch(showListPanel());
    };

    const onShowHistoryPanel = () => {
        dispatch(showHistoryPanel());
    };

    const renderUserElements = () => {
        if (loggedIn) {
            return (
                <div className="uk-flex uk-flex-middle">
                    {DOMService.isMobile() && (
                        <ul className="mg-nav uk-visible-small">
                            <li>
                                <DarkModeToggle />
                            </li>
                        </ul>
                    )}
                    <div className="mg-padding-0-15">
                        {email && <UserDropdownLoader />}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <header className="mg-header">
            <MangoolsNavBar
                appName={LinkRefSources.KWFINDER_APP}
                rightEl={renderUserElements()}
                onShowAppPanel={onShowAppPanel}
                logoDarkSrc={mnglsImageWhite}
                logoLightSrc={mnglsImageWhite}
                env={APP_CONFIG.APP_ENV}
                userPlanType={planType}
                mangoolsApiHost={APP_CONFIG.MANGOOLS_API_HOST}
            />
            <div className="mg-header-app uk-flex uk-flex-space-between mg-padding-0-15">
                <MenuLeft
                    currentRoute={currentRoute}
                    listCount={listCount}
                    onShowAppPanel={onShowAppPanel}
                    onShowHistoryPanel={onShowHistoryPanel}
                    onShowListPanel={onShowListPanel}
                />
                <MenuRight
                    currentRoute={currentRoute}
                    helpDropdownVisible={helpDropdownVisible}
                    linksDropdownVisible={linksDropdownVisible}
                    onCloseHelpDropdown={onCloseHelpDropdown}
                    onCloseLinksDropdown={onCloseLinksDropdown}
                    onShowHelpDropdown={onShowHelpDropdown}
                    onShowLinksDropdown={onShowLinksDropdown}
                    onShowShortcutsMessage={onShowShortcutsMessage}
                    trackedKeywordLimit={trackedKeywordLimit}
                />
            </div>
        </header>
    );
}

NavbarContainer.propTypes = {
    currentRoute: string,
};

export default NavbarContainer;
