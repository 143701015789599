import isNil from 'ramda/src/isNil';
import { ANY_LANGUAGE } from 'mangools-commons/dist/constants/Languages';
import { ANYWHERE } from 'mangools-commons/dist/constants/Locations';

export const defaultCompetitorQuerySelector = state => state.ui.defaults.competitorMode.query;
export const defaultCompetitorRowExpandedSelector = state => state.ui.defaults.competitorMode.competitorRowExpanded;
export const defaultGoogleQuerySelector = state => state.ui.defaults.googleMode.query;
export const defaultSharedCurrentKeywordGraphSelector = state => state.ui.defaults.shared.currentKeywordGraph;
export const defaultSharedDataSourceSelector = state => state.ui.defaults.shared.dataSource;
export const defaultSharedDataSubSourceSelector = state => state.ui.defaults.shared.dataSubSource;

const getDefaultLanguage = language => {
    if (language.label === ANY_LANGUAGE.label) {
        return ANY_LANGUAGE;
    } else if (isNil(language) || isNil(language.code)) {
        return ANY_LANGUAGE;
    } else {
        return language;
    }
};

export const defaultGoogleLanguageSelector = state => {
    const language = state.ui.defaults.googleMode.language; // eslint-disable-line prefer-destructuring
    return getDefaultLanguage(language);
};

const getDefaultLocation = location => {
    if (location.label === ANYWHERE.label) {
        return ANYWHERE;
    } else if (isNil(location) || isNil(location.code)) {
        return ANYWHERE;
    } else {
        return location;
    }
};

export const defaultGoogleLocationSelector = state => {
    const location = state.ui.defaults.googleMode.location; // eslint-disable-line prefer-destructuring
    return getDefaultLocation(location);
};

export const defaultCompetitorLocationSelector = state => {
    const location = state.ui.defaults.competitorMode.location; // eslint-disable-line prefer-destructuring
    return getDefaultLocation(location);
};
