import React from 'react';
import { func, string } from 'prop-types';
import NumberFormatterService from 'mangools-commons/dist/services/NumberFormatterService';
import classnames from 'classnames';

import { DEFAULT_LIST_KEYWORDS_MAX_LIMIT } from 'reducers/userReducer';

import ListType from 'types/ListType';

const KeywordListItem = props => {
    const { onClick, list, selectedListId } = props;
    const keywordCount = list.keywordIds.length;

    const badgeClasses = classnames({
        'color-red': keywordCount >= DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
        'color-black': keywordCount < DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
    });

    const wrapperClasses = classnames({
        'is-disabled': keywordCount >= DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
        'is-active': list.id === selectedListId,
    });

    const handleClick = () => {
        if (keywordCount < DEFAULT_LIST_KEYWORDS_MAX_LIMIT) {
            onClick(list.id);
        }
    };

    return (
        <li className={wrapperClasses}>
            <button
                aria-checked={list.id === selectedListId}
                onClick={handleClick}
                role="menuitemradio"
                type="button"
                tabIndex="-1"
            >
                <div className="uk-flex uk-flex-space-between">
                    <div className="mg-truncate">{list.name}</div>
                    <div className="color-grey font-10 mg-margin-l-10 uk-flex-item-none">
                        <span title="Number of keywords in list">
                            <span className={badgeClasses}>
                                {NumberFormatterService.simpleCommaNoDecimals(keywordCount)}
                            </span>
                            &nbsp;/ {NumberFormatterService.simpleCommaNoDecimals(DEFAULT_LIST_KEYWORDS_MAX_LIMIT)}
                        </span>
                    </div>
                </div>
            </button>
        </li>
    );
};

KeywordListItem.propTypes = {
    onClick: func.isRequired,
    selectedListId: string,
    list: ListType.isRequired,
};

export default KeywordListItem;
