import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { func, number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isNil, trim } from 'ramda';
import { Input } from 'mangools-react-components/src';

import swImage from 'images/serpwatcher-icon.svg';

import Defaults from 'mangools-commons/dist/constants/Defaults';
import NumberFormatterService from 'mangools-commons/dist/services/NumberFormatterService';

import DateFormatterService from 'services/DateFormatterService';
import { DEFAULT_LIST_KEYWORDS_MAX_LIMIT } from 'reducers/userReducer';

import Tooltip from 'components/other/Tooltip';

const ListPanelItem = ({
    createdAt,
    id,
    keywordCount,
    name,
    onClose,
    onDeleteList,
    onExportList,
    showListLink,
    updatedAt,
    isPreselected,
    onRenameList,
    onShowTrackInSerpwatcherMessage,
}) => {
    const [editing, setEditing] = useState(false);
    const [newName, setNewName] = useState(name);
    const nameInput = useRef(null);

    const deleteList = () => {
        onDeleteList({ listId: id, listName: name });
    };

    const exportList = () => {
        onExportList(id);
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        if (!editing) {
            setEditing(true);
            setTimeout(() => {
                if (nameInput.current) {
                    nameInput.current.focus();
                }
            }, 0);
        } else {
            if (nameInput.current) {
                nameInput.current.blur();
            }
        }
    };

    const handleBlur = () => {
        const trimmedName = trim(newName);
        if (trimmedName.length >= 1) {
            if (trimmedName !== name) {
                onRenameList(id, newName);
            } else {
                setNewName(trimmedName);
            }
        } else {
            setNewName(name);
        }
        setEditing(false);
    };

    const handleNewNameChange = (e) => {
        setNewName(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (nameInput.current) {
            nameInput.current.blur();
        }
    };

    const handleTrackInSerpwatcherClick = () => {
        onShowTrackInSerpwatcherMessage(id);
    };

    const formatTimestamp = (timestamp) => {
        if (!isNil(timestamp)) {
            return DateFormatterService.formatBasic(timestamp);
        }
        return Defaults.NOT_AVAILABLE;
    };

    const renderLinkBody = () => {
        const badgeClasses = classnames({
            'color-red': keywordCount >= DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
            'color-black': keywordCount < DEFAULT_LIST_KEYWORDS_MAX_LIMIT,
        });

        return (
            <>
                <div className="kw-listname font-18 mg-truncate uk-display-inline-block uk-text-bold uk-width-5-10 uk-width-medium-5-10">
                    {editing ? (
                        <form
                            onSubmit={handleSubmit}
                            className="uk-block uk-width-1-1 uk-position-relative color-black"
                            style={{ marginTop: '-5px' }}
                        >
                            <FontAwesomeIcon
                                icon="pencil-alt"
                                aria-hidden="true"
                                className="font-12 mg-margin-r-5 color-grey"
                            />
                            <Input
                                className="mg-editable is-small mg-truncate uk-width-1-1 uk-text-bold"
                                minLength={2}
                                maxLength={100}
                                onBlur={handleBlur}
                                onChange={handleNewNameChange}
                                inputRef={nameInput}
                                required
                                spellCheck={false}
                                title={newName}
                                type="text"
                                value={newName}
                            />
                        </form>
                    ) : (
                        <span>{newName}</span>
                    )}
                </div>
                <div className="uk-flex font-12 color-grey mg-margin-t-5">
                    <div className="uk-width-1-3">
                        <span title="Number of keywords in list">
                            <strong className={badgeClasses}>
                                {NumberFormatterService.simpleCommaNoDecimals(keywordCount)}
                            </strong>
                            &nbsp;/ {NumberFormatterService.simpleCommaNoDecimals(DEFAULT_LIST_KEYWORDS_MAX_LIMIT)}
                        </span>
                    </div>
                    <div className="uk-width-1-3">
                        <span title={`Created: ${formatTimestamp(createdAt)}`}>
                            {formatTimestamp(updatedAt)}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const renderLink = () => {
        const wrapperClasses = classnames('mg-card', 'mg-padding-30', 'mg-no-underline', 'uk-display-block', {
            'is-focus': isPreselected,
            'is-hover': !editing,
        });

        if (keywordCount > 0) {
            return editing ? (
                <div className={wrapperClasses}>{renderLinkBody()}</div>
            ) : (
                <Link onClick={onClose} to={showListLink} className={wrapperClasses}>
                    {renderLinkBody()}
                </Link>
            );
        }
        return (
            <div
                className="mg-card mg-padding-30 mg-no-interactive"
                style={{ opacity: keywordCount > 0 ? 1 : 0.5 }}
            >
                {renderLinkBody()}
            </div>
        );
    };

    return (
        <div className="mg-margin-b-15 uk-position-relative ln-11">
            {renderLink()}
            <div className="uk-position-top-right mg-padding-30 uk-position-z-index uk-height-1-1 uk-flex uk-flex-middle">
                <button
                    aria-label="Track in SERPWatcher"
                    className="mg-btn is-white is-xsmall mg-margin-r-10"
                    type="button"
                    onClick={handleTrackInSerpwatcherClick}
                    disabled={keywordCount === 0}
                >
                    <img alt="SERPWatcher" src={swImage} style={{ marginTop: '-2px' }} width="10" />
                    <span className="uk-hidden-small mg-margin-l-5">Track keywords</span>
                </button>
                <Tooltip overlayContent="Rename list">
                    <button
                        aria-label="Rename the list"
                        className="mg-icon-btn"
                        onMouseDown={handleEditClick}
                        tabIndex="-1"
                        type="button"
                    >
                        <FontAwesomeIcon icon="pencil-alt" />
                    </button>
                </Tooltip>
                <Tooltip overlayContent="Export list">
                    <button
                        aria-label="Export to CSV"
                        className="mg-icon-btn"
                        onClick={exportList}
                        tabIndex="-1"
                        type="button"
                        disabled={keywordCount === 0}
                    >
                        <FontAwesomeIcon icon="download" />
                    </button>
                </Tooltip>
                <Tooltip overlayContent="Delete list">
                    <button
                        aria-label="Delete list"
                        className="mg-icon-btn"
                        onClick={deleteList}
                        tabIndex="-1"
                        type="button"
                    >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                    </button>
                </Tooltip>
            </div>
        </div>
    );
};

ListPanelItem.propTypes = {
    createdAt: number.isRequired,
    id: string.isRequired,
    keywordCount: number.isRequired,
    name: string.isRequired,
    onClose: func.isRequired,
    onDeleteList: func.isRequired,
    onExportList: func.isRequired,
    showListLink: string.isRequired,
    updatedAt: number.isRequired,
    isPreselected: bool.isRequired,
    onRenameList: func.isRequired,
    onShowTrackInSerpwatcherMessage: func.isRequired,
};

export default ListPanelItem;
