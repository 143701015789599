import Defaults from 'mangools-commons/dist/constants/Defaults';
import { ascend, compose, defaultTo, isNil, map, prop, propOr, sortWith } from 'ramda';

export const parseIntWithNVFallback = value => {
    if (isNil(value)) {
        return Defaults.NO_VALUE;
    } else {
        return parseInt(value, 10);
    }
};

export const parseFloatWithNVFallback = value => {
    if (isNil(value)) {
        return Defaults.NO_VALUE;
    } else {
        return parseFloat(value);
    }
};

export const parseStringWithNAFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return value;
    }
};

export const parseLocationLabel = value => {
    if (!isNil(value) && typeof value === 'string') {
        return value.replace(/,/g, ', ');
    } else {
        return null;
    }
};

const parseMSVSearchCount = searchCount => {
    const parsedSearchCount = parseInt(searchCount, 10);

    return defaultTo(0, parsedSearchCount);
};

export const parseMSV = compose(
    sortWith([ascend(prop('year')), ascend(prop('month'))]),
    map(([year, month, count]) => ({
        count: parseMSVSearchCount(count),
        month: parseInt(month, 10),
        year: parseInt(year, 10),
    })),
);

export const parseMSVWithoutCountDefault = msv => {
    if (!msv || !msv.length || msv.length === 0) {
        return [];
    }
    return compose(
        sortWith([ascend(prop('year')), ascend(prop('month'))]),
        map(item => {
            const year = propOr(null, 0, item);
            const month = propOr(null, 1, item);
            const count = propOr(null, 2, item);

            return {
                count,
                month: month ? parseInt(month, 10) : null,
                year: year ? parseInt(year, 10) : null,
            };
        }),
    )(msv);
};

const average = data => {
    if (!data.length) {
        return null;
    }

    const resultSum = data.reduce((sum, value) => sum + value, 0);
    return resultSum / data.length;
};

export const computeTrendPercent = msv => {
    if (!msv || !msv.length || msv.length === 0) {
        return [];
    }
    // current 3 month
    const currentDateFirstMonth = new Date();
    const currentDateSecondMonth = new Date();
    const currentDateThird = new Date();
    currentDateFirstMonth.setMonth(currentDateFirstMonth.getMonth() - 4);
    currentDateSecondMonth.setMonth(currentDateSecondMonth.getMonth() - 3);
    currentDateThird.setMonth(currentDateThird.getMonth() - 2);

    // previous 3 month
    const previousDateFirstMonth = new Date();
    const previousDateSecondMonth = new Date();
    const previousDateThird = new Date();

    previousDateFirstMonth.setFullYear(previousDateFirstMonth.getFullYear() - 1);
    previousDateSecondMonth.setFullYear(previousDateSecondMonth.getFullYear() - 1);
    previousDateThird.setFullYear(previousDateThird.getFullYear() - 1);

    previousDateFirstMonth.setMonth(previousDateFirstMonth.getMonth() - 4);
    previousDateSecondMonth.setMonth(previousDateSecondMonth.getMonth() - 3);
    previousDateThird.setMonth(previousDateThird.getMonth() - 2);

    const currentDates = [currentDateFirstMonth, currentDateSecondMonth, currentDateThird];
    const previousDates = [previousDateFirstMonth, previousDateSecondMonth, previousDateThird];

    const currentVolumes = [];
    const previousVolumes = [];

    currentDates.forEach(date => {
        const data = msv.find(sv => sv.year === date.getFullYear() && sv.month === date.getMonth());
        if (data) {
            currentVolumes.push(data.count);
        } else {
            currentVolumes.push(null);
        }
    });

    previousDates.forEach(date => {
        const data = msv.find(sv => sv.year === date.getFullYear() && sv.month === date.getMonth());
        if (data) {
            previousVolumes.push(data.count);
        } else {
            previousVolumes.push(null);
        }
    });

    // filter null value
    const currentYearVolumesFiltered = currentVolumes.filter(item => item !== null);
    const previousYearVolumesFiltered = previousVolumes.filter(item => item !== null);

    if (currentYearVolumesFiltered.length === 0 || previousYearVolumesFiltered.length === 0) {
        return null;
    }

    const currentYearAverage = average(currentYearVolumesFiltered);
    const previousYearAverage = average(previousYearVolumesFiltered);

    if (currentYearAverage === 0 && previousYearAverage === 0) {
        return 0;
    }

    if (currentYearAverage === 0 && previousYearAverage !== 0) {
        return -100;
    }

    if (currentYearAverage !== 0 && previousYearAverage === 0) {
        return 100;
    }

    return Math.round(((currentYearAverage - previousYearAverage) / previousYearAverage) * 100);
};

export const parseIntWithFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return parseInt(value, 10);
    }
};

export const decodeUrl = rawUrl => {
    try {
        const decodedUrl = decodeURIComponent(rawUrl);
        return decodedUrl;
    } catch (e) {
        // NOTE:
        // i.e. malformed URI error
        // we are fallbacking to the original URL, it should come from SERP so we believe in it :)
        return rawUrl;
    }
};
