import getAppUrl from './AppEnvUrl';
import { LinkRefSources } from './LinkRefSources';
import { MANGOOLS_PATHS } from './Urls';

// Constructs app URLS depending on ENV and HOST. Imported in react apps in src/config/env/ files,
// usage example: APP_CONFIG.MANGOOLS_URLS.LOGIN_NO_REDIRECT in SW src/components/messages/NeedToSignInMessage.jsx
export const getMangoolsUrls = env => {
  return Object.keys(MANGOOLS_PATHS).reduce((acc, path) => ({
    ...acc,
    [path]: `${getAppUrl(LinkRefSources.MANGOOLS_APP, env)}/${MANGOOLS_PATHS[path]}`
  }), {});
};