import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import ShortcutService from 'mangools-commons/dist/services/ShortcutService';

import Overlay from 'components/layout/Overlay';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import MessageContainer from 'components/messages/MessageContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll, closeShortcutsMessage, showShortcutsMessage } from 'actions/uiActions';
import { gtmTrack } from 'actions/analyticsActions';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';
import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';

import {
    areShortcutsDisabledSelector,
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
} from 'selectors/uiSelectors';

import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';
import { analyticsActions, analyticsEvents } from 'constants/analytics';
import { Alert, IssueWarningBanner } from 'mangools-react-components/src';

const LayoutContainer = ({
    mobileOverlayVisibility,
    onlineStatus,
    overlayVisibility,
    topLevelMessageVisible,
    shortcutsMessageVisible,
    areShortcutsDisabled,
    onCloseAll,
    onShowShortcutsMessage,
    onCloseShortcutsMessage,
    onGtmTrack,
}) => {
    const location = useLocation();

    const handleToggleShortcuts = () => {
        if (shortcutsMessageVisible) {
            onCloseShortcutsMessage();
        } else {
            onShowShortcutsMessage();
        }
    };

    const handleCloseAllShortcut = () => {
        onGtmTrack({
            action: analyticsActions.CLOSE_ALL,
            event: analyticsEvents.KB_SHORTCUT,
        });
        onCloseAll();
    };

    const appClasses = classnames({
        'is-offline': onlineStatus === false,
        'kwfinder-app': true,
        'is-messaged': topLevelMessageVisible,
    });

    const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
        handlers: {
            [CLOSE_ALL]: { func: handleCloseAllShortcut, preventDisable: true },
            [SHOW_SHORTCUTS_TOGGLE]: { func: handleToggleShortcuts },
        },
        areShortcutsDisabled,
        shortcuts,
    });

    return (
        <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}>
            <div className={appClasses}>
                <TopLevelMessageContainer />
                <NavbarContainer currentRoute={location.pathname} />
                {/*<IssueWarningBanner />*/}
                <Outlet />
                <PanelContainer />
                <MessageContainer />
                <Overlay
                    mobile={false}
                    onClick={onCloseAll}
                    visibility={overlayVisibility}
                />
                <Overlay
                    mobile
                    onClick={onCloseAll}
                    visibility={mobileOverlayVisibility}
                />
                <div className="uk-hidden-small">
                    <Alert />
                </div>
            </div>
        </GlobalHotKeys>
    );
};

LayoutContainer.propTypes = {
    children: PropTypes.node.isRequired,
    mobileOverlayVisibility: PropTypes.bool.isRequired,
    onlineStatus: PropTypes.bool.isRequired,
    overlayVisibility: PropTypes.bool.isRequired,
    topLevelMessageVisible: PropTypes.bool.isRequired,
    shortcutsMessageVisible: PropTypes.bool.isRequired,
    areShortcutsDisabled: PropTypes.bool.isRequired,
    onCloseAll: PropTypes.func.isRequired,
    onShowShortcutsMessage: PropTypes.func.isRequired,
    onCloseShortcutsMessage: PropTypes.func.isRequired,
    onGtmTrack: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    topLevelMessageVisible:
        newUserMessageVisibilitySelector(state) ||
        announcementMesageVisibilitySelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    areShortcutsDisabled: areShortcutsDisabledSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onCloseAll() {
        dispatch(closeAll());
    },
    onShowShortcutsMessage() {
        dispatch(showShortcutsMessage());
        dispatch(
            gtmTrack({
                action: analyticsActions.SHOW_SHORTCUTS_TOGGLE,
                event: analyticsEvents.KB_SHORTCUT,
            })
        );
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onGtmTrack(payload) {
        dispatch(gtmTrack(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
